import React from 'react';
import PropTypes from 'prop-types';
import { withFocus } from '@accedo/vdkweb-navigation';
import { RoundButton } from '#/components/RoundButton/RoundButton';
import ImageWithPreload from '#/components/ImageWithPreload/ImageWithPreload';
import useItemStyle from '#/hooks/useItemStyle';
import { getTranslation } from '#/services/i18n';
import { formatProgramTime } from '@accedo/vdkweb-epg';
import dayjs from 'dayjs';

import heroItemTheme from './heroItem.scss';

const HeroItem = ({
  onClick,
  theme = heroItemTheme,
  nav = {},
  data,
  containerStyle,
  buttonText,
  height,
  width
}) => {
  const backgroundClassName = `${theme.background} ${
    nav.isFocused ? theme.backgroundFocused : ''
  }`;
  const { itemStyle } = useItemStyle({ data, width, height, type: 'hero' });
  const liveHeroImageUrl = data.poster || null;
  const logoChannelUrl = data.logo?.fileUrl || null;

  return (
    <div style={{ ...containerStyle }}>
      <div
        className={backgroundClassName}
        onMouseOver={nav.onMouseOver}
        onMouseOut={nav.onMouseOut}
      >
        <ImageWithPreload src={liveHeroImageUrl || itemStyle.imageUrl} />
        <div className={theme.contentContainer}>
          {logoChannelUrl && (
            <div className={theme.logo}>
              <img src={logoChannelUrl} alt="Channel Logo" />
            </div>
          )}
          <div className={theme.title}>{data.displayText || data.title}</div>
          {data.startTime && (
            <div className={theme.subtitle}>
              {formatProgramTime(
                dayjs(data.startTime),
                dayjs(data.endTime),
                'HH:mm'
              )}
            </div>
          )}
          <RoundButton
            className={theme.button}
            onClick={onClick}
            displayText={
              data.startTime
                ? 'Live Now'
                : buttonText || getTranslation('moreDetails')
            }
            nav={nav}
          />
        </div>
      </div>
    </div>
  );
};

HeroItem.propTypes = {
  buttonText: PropTypes.string,
  data: PropTypes.object,
  containerStyle: PropTypes.any,
  height: PropTypes.number,
  nav: PropTypes.object,
  onClick: PropTypes.func,
  theme: PropTypes.object,
  width: PropTypes.string
};

export default withFocus(HeroItem);
