/**
 * @module models/OVP
 */

/**
 * @typedef {Object} Season
 * @property {String} id Season ID
 * @property {String} title Season Title
 */

/**
 * Season Model
 * @param {Object} rawSeason Season
 * @returns {Season} Season
 */
const Season = rawSeason => {
  if (rawSeason) {
    const { id, title, tvSeasonNumber } = rawSeason;
    return { id, title: title.toUpperCase(), tvSeasonNumber, type: 'season' };
  }
};

export default Season;
