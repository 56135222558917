/**
 * @module services/menu
 * @description
 * Service to handle the fetch of menu items
 */
import cms from '#/providers/cms';
import menuModel from '#/models/menu';
import { PROVIDER_TYPE } from '#/config/constants';

import fetcher from '../../helpers/fetcher';

const getMenu = async ({ items, entryId, isAuthenticated, locale }) => {
  let rawMenu;
  if (items) {
    rawMenu = { items };
  } else if (!rawMenu) {
    rawMenu = await fetcher({
      cacheId: `${PROVIDER_TYPE.cms}-${entryId}${locale ? `-${locale}` : ''}`,
      fetchFn: () => cms.getMenu({ id: entryId, options: { locale } })
    });
  }
  const menu = menuModel(rawMenu);

  // return only usable items according to the user state
  const menuItems = menu.items.filter(
    item =>
      !(item.hiddenWhenAuth && isAuthenticated) &&
      !(item.requireAuthentication && !isAuthenticated)
  );

  const primaryItems = [];
  const secondaryItems = [];
  menuItems.forEach(item => {
    /** The default itemGroup is the 'primary' group */
    item.itemGroup !== 'secondary'
      ? primaryItems.push(item)
      : secondaryItems.push(item);
  });

  return { primaryItems, secondaryItems, title: menu.displayText };
};

export { getMenu };
