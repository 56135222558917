import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import FailedToLoad from '#/views/FailedToLoad/FailedToLoad';
import ContainerPage from '#/views/ContainerPage/ContainerPage';

import useApiError from '#/hooks/useApiError';
import useI18n from '#/hooks/useI18n';
import useAppConfig from '#/hooks/useAppConfig';
import { getPageLayout, getThemeByPage } from '#/services/cms';
import setCssVariables from '#/utils/setCssVariables';

import defaultLocalTheme from '#/theme/defaultTheme.json';

const ModularPage = ({ templateComp, pageId, ...props }) => {
  // const { pageId = null } = useParams();
  const { addError, removeError } = useApiError();
  const { currentLocale } = useI18n();
  const { code: localeCode } = currentLocale || {};
  const { config = {} } = useAppConfig();
  const defaultTheme = config.theme || defaultLocalTheme;

  const [pageInfo, setPageInfo] = useState({
    containers: null,
    theme: null,
    id: '',
    errorMessage: null,
    failedToLoad: false,
    loaded: false
  });

  const getPageData = useCallback(async () => {
    try {
      const page = await getPageLayout({ id: pageId, locale: localeCode });
      const theme = await getThemeByPage({
        id: pageId,
        locale: localeCode
      });
      if (!page) {
        const errorMessage = 'Error Message';
        setPageInfo(prevInfo => ({
          ...prevInfo,
          failedToLoad: true,
          errorMessage
        }));
      }
      setPageInfo(prevInfo => ({
        ...prevInfo,
        loaded: true,
        theme,
        ...page // id, displayText, containers
      }));
    } catch (error) {
      setPageInfo(prevInfo => ({
        ...prevInfo,
        failedToLoad: true,
        errorMessage: error
      }));
    }
  }, [pageId, localeCode]);

  const pageProps = useMemo(
    () => ({
      id: pageInfo.id
    }),
    [pageInfo]
  );

  useEffect(() => {
    if (localeCode) {
      getPageData();
    }
  }, [getPageData, localeCode]);

  useEffect(() => {
    if (pageInfo.failedToLoad) {
      addError();
    } else {
      removeError();
    }
  }, [pageInfo.failedToLoad, addError, removeError]);

  useEffect(() => {
    if (pageInfo.loaded) {
      if (!pageInfo?.theme) {
        setCssVariables(defaultTheme);
      } else {
        setCssVariables(pageInfo.theme);
      }
    }
  }, [pageInfo.loaded, pageInfo.theme, defaultTheme]);

  if (pageInfo.failedToLoad) {
    return <FailedToLoad debugInfo={pageInfo.errorMessage} />;
  }

  if (!pageInfo.loaded) {
    return null;
  }

  if (pageInfo.id && pageInfo.id !== pageId) {
    // Instead of returning null, we can return an Skeleton
    // but we don't have skeleton for pages yet
    // This is needed because when there is a change route that reuse ModularPage
    // the old (current) data is still present and it could cause a focus loss
    // on the next route.
    return null;
  }

  return (
    <ContainerPage
      containers={pageInfo.containers}
      pageProps={pageProps}
      displayText={pageInfo.displayText}
      key={pageId}
      {...props}
    />
  );
};

ModularPage.propTypes = {
  pageId: PropTypes.string.isRequired,
  containers: PropTypes.array,
  dispatch: PropTypes.func,
  displayText: PropTypes.string,
  errorMessage: PropTypes.string,
  failedToLoad: PropTypes.bool,
  loaded: PropTypes.bool,
  template: PropTypes.string,
  templateComp: PropTypes.func
};

export default ModularPage;
