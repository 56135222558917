import React from 'react';
import PropTypes from 'prop-types';
import { withFocus } from '@accedo/vdkweb-navigation';
import ImageWithPreload from '#/components/ImageWithPreload/ImageWithPreload';
import useItemStyle from '#/hooks/useItemStyle';
import classNames from 'classnames';
import { FocusDiv } from '@accedo/vdkweb-tv-ui';

import bannerItemTheme from './bannerItem.scss';

const BannerItem = ({
  onClick,
  theme = bannerItemTheme,
  nav = {},
  data,
  containerStyle,
  height,
  width
}) => {
  const backgroundClassName = classNames(theme.background, {
    [theme.backgroundFocused]: nav.isFocused
  });
  const { itemStyle } = useItemStyle({ data, width, height, type: 'hero' });
  const liveHeroImageUrl = data.poster || null;

  return (
    <div style={{ ...containerStyle }}>
      <div
        className={backgroundClassName}
        onMouseOver={nav.onMouseOver}
        onMouseOut={nav.onMouseOut}
      >
        <ImageWithPreload src={liveHeroImageUrl || itemStyle.imageUrl} />
        <FocusDiv
          className={classNames(theme.contentContainer, {
            [theme.containerFocused]: nav.isFocused
          })}
          nav={nav}
          onClick={onClick}
        >
          <div style={{ display: 'none' }} />
        </FocusDiv>
      </div>
    </div>
  );
};

BannerItem.propTypes = {
  data: PropTypes.object,
  containerStyle: PropTypes.any,
  height: PropTypes.number,
  nav: PropTypes.object,
  onClick: PropTypes.func,
  theme: PropTypes.object,
  width: PropTypes.string
};

export default withFocus(BannerItem);
