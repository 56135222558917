import { useCallback, useContext } from 'react';
import { PageContext } from '#/context/PageContext';

const usePage = () => {
  const pageContext = useContext(PageContext);
  if (pageContext === undefined) {
    throw new Error('usePage must be used within a PageProvider');
  }
  const {
    getCurrentPageInfo,
    updateCurrentPageInfo,
    updateCurrentPageId,
    updatePageFilter
  } = pageContext;

  const getCurrentPageId = useCallback(() => {
    return getCurrentPageInfo().currentPageId;
  }, [getCurrentPageInfo]);

  const getPreviousPageId = useCallback(() => {
    return getCurrentPageInfo().previousPageId;
  }, [getCurrentPageInfo]);

  const getPageFilter = useCallback(() => {
    return getCurrentPageInfo().pageFilter;
  }, [getCurrentPageInfo]);

  const getPageLatestStateById = useCallback(
    id => {
      const { pageLatestState } = getCurrentPageInfo();
      return pageLatestState[id];
    },
    [getCurrentPageInfo]
  );

  const setPageLatestStateById = useCallback(
    (id, state) => {
      updateCurrentPageInfo({ currentPageId: id, newState: state });
    },
    [updateCurrentPageInfo]
  );

  const setCurrentPageId = useCallback(
    currentPageId => updateCurrentPageId(currentPageId),
    [updateCurrentPageId]
  );

  // TODO: Dev, Check if you need it or not
  const setPageFilter = useCallback(
    pageFilter => {
      updatePageFilter({ pageFilter });
    },
    [updatePageFilter]
  );

  return {
    getCurrentPageId,
    getPreviousPageId,
    getPageFilter,
    getPageLatestStateById,
    setCurrentPageId,
    setPageLatestStateById,
    setPageFilter
  };
};

export default usePage;
