import androidTv from '@accedo/xdk-device-android-webview';
import fireTv from '@accedo/xdk-device-amazonfire-contrib';
import workstation, { ID as WORKSTATION } from '@accedo/xdk-device-workstation';
import tizen from '@accedo/xdk-device-samsung-tizen';
import { hlsjs } from '@accedo/xdk-players-contrib';
import { shaka } from './shaka';

const pollingNetwork = {
  polling: {
    interval: 60,
    url: 'https://jsonplaceholder.typicode.com/posts/1'
  }
};

const CONFIG = {
  devices: {
    packages: [
      androidTv,
      fireTv.addPlayer(hlsjs, {
        // enableWorker: true, // true is the default value
        // enableSoftwareAES: false, // protocol must be in HTTPS, if `true` protocol in HTTP
        maxBufferLength: 0, // 30 is the default
        // maxMaxBufferLength: 20, // 600 is the default
        // maxBufferSize: 10,
        // maxBufferHole: 0.5,
        // backBufferLength: 4, // Let's check if we need it as 4 or set to 0; Infinity is the default one
        // liveSyncDurationCount: 3,
        // logLevel: 'debug',
        mediaRangeEvent: true
      }),
      tizen.removeAllPlayers().addPlayer(shaka),
      // .addPlayer(hlsjs, {
      //   debug: true,
      //   // maxBufferLength: 0, // 30 is the default
      //   // nudgeMaxRetry: 6,
      //   // mediaRangeEvent: true
      //   enableWorker: true,
      //   lowLatencyMode: true,
      //   backBufferLength: 90,
      //   frontBufferFlushThreshold: 10 // Reducing the buffer size for Tizen
      //   // backBufferLength: 0
      // }),
      workstation
        .addPlayer(shaka)
        .addPlayer(hlsjs, {
          nudgeMaxRetry: 6,
          mediaRangeEvent: true
        })
        .forceDetection(true)
    ],
    details: {
      [WORKSTATION]: {
        network: pollingNetwork
      }
    },
    // Storages configurations
    storages: {
      cookie: {
        name: 'elevate-app'
      }
    }
  },
  logging: {
    level: -1,
    visibleOnStart: true
  }
};

export default CONFIG;
