/**
 * @module services/list
 * @description
 * Service to handle the fetch of a move list
 */

import ovp from '#/providers/ovp';
import { PROVIDER_TYPE } from '#/config/constants';
import Movie from '#/models/movie/movie';
import Show from '#/models/show/show';

import fetcher from '../../helpers/fetcher';
/**
 * Get list of movies by keyword
 * @param {Object} keys Keyword to search
 * @returns {Array<Object>} Results
 */
const getMoviesListByKeys = async ({ keys }) => {
  const rawMovies = await fetcher({
    cacheId: `${PROVIDER_TYPE.ovp}-movies-by-keys-${keys.join()}`,
    fetchFn: () => ovp.searchMovies(keys.join())
  });
  return rawMovies.entries.map(movie => Movie(movie));
};

/**
 * Get list of movies by category
 * @param {String} category Category id
 * @returns {Array<Object>} Results
 */
const getMoviesByCategory = async category => {
  const rawMovies = await fetcher({
    cacheId: `${PROVIDER_TYPE.ovp}-movies-category-${category}`,
    fetchFn: () => ovp.getMoviesByCategory(category)
  });
  return rawMovies?.map(movie => Movie(movie));
};

/**
 * Get list of tv shows by category
 * @param {String} category Category id
 * @returns {Array<Object>} Results
 */
const getTvShowsByCategory = async category => {
  const rawTvShows = await fetcher({
    cacheId: `${PROVIDER_TYPE.ovp}-shows-category-${category}`,
    fetchFn: () => ovp.getTvShowsByCategory(category)
  });

  return rawTvShows?.map(show => Show(show));
};

export { getMoviesListByKeys, getMoviesByCategory, getTvShowsByCategory };
