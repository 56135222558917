/**
 * @module models/user
 */
/**
 * This model represents the logged user. These properties
 * are pre defined by this template and you can modify
 * according your User model properties.
 *
 * User representation
 * @typedef {Object} User
 * @property {String} username Username property
 * @property {String} userId User id property
 * @property {String} email Email property
 * @property {String} fullName Full name property
 * @property {String} token Token property
 * @property {String} deviceId Device id property
 * @property {Boolean} isPaired Entitlement property
 */

/**
 * User Model
 * @param {Object} rawUser User data
 * @param {String} rawUser.username Username param
 * @param {String} rawUser.userId user id param
 * @param {String} rawUser.email Email param
 * @param {String} rawUser.token Token param
 * @param {String} rawUser.deviceId Device id param
 * @param {Boolean} [rawUser.paired] Paired param
 * @returns {User} user - The {@link User} representation
 */
const User = ({ username, email, token, deviceId, userId, paired = false }) => {
  const fullName = () => username;
  const isPaired = () => paired;
  return {
    username,
    userId,
    email,
    fullName: fullName(),
    token,
    deviceId,
    isPaired: isPaired()
  };
};

export default User;
