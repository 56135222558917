import controlConfig from './config';
import { getGID } from './mapIds';
import * as controlUtils from './utils';

let extendedConfig = { ...controlConfig };

let accedoOneClientInstance = controlUtils.getControlClient(extendedConfig);

export const accedoOneClientInstanceBuilder = userId => {
  if (userId) {
    const calculatedGid = getGID(userId);
    if (calculatedGid !== extendedConfig.gid) {
      extendedConfig = {
        ...extendedConfig,
        gid: calculatedGid
      };
      accedoOneClientInstance = controlUtils.getControlClient(extendedConfig);
    }
    return accedoOneClientInstance;
  }

  // If was previously logged-in but now it's not, we need to recreate
  if (
    // New default for Bell POC
    // Previously 'amagi'
    extendedConfig.gid !== getGID()
  ) {
    extendedConfig.gid = getGID('amagi');
    accedoOneClientInstance = controlUtils.getControlClient(extendedConfig);
  }
  return accedoOneClientInstance;
};
