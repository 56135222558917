import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { focusManager, withFocus } from '@accedo/vdkweb-navigation';

import isNumber from '#/utils/isNumber';
import vw from '#/utils/vw';
import { RoundButton } from '#/components/RoundButton/RoundButton';

import {
  ArrowUpIcon as IconUp,
  ArrowDownIcon as IconDown,
  ArrowRightIcon as IconRight,
  ArrowLeftIcon as IconLeft
} from '#/components/Icons/Icons';
import { usePointer } from '#/context/PointerContext';
import componentTheme from './NavigationArrow.scss';

// eslint-disable-next-line valid-jsdoc
/**
 * NavigationArrow is a component that is used to
 * allow navigating/scrolling lists using pointer.
 * NavigationArrow is displayed at list ends and
 * can only be focused with a pointer device when
 * pointer device is enabled and supported.
 * NavigationArrow will automatically hide when
 * pointer becomes disabled, and the enabled state
 * can be controlled with a prop so the arrow is
 * only enabled when scrolling/navigation is
 * possible.
 */
const NavigationArrow = ({
  up = false,
  down = false,
  left = false,
  right = false,
  alwaysRender = true,
  width: widthProp = 50,
  height: heightProp = 50,
  className,
  theme,
  returnParent,
  nav,
  enabled: enabledProp = false,
  isFocused,
  style,
  onClick,
  label = 'move',
  ...props
}) => {
  const { pointerEnabled, pointerSupported } = usePointer();

  React.useEffect(() => {
    // navigation arrow must not be focused when pointer is not enabled - move focus to parent
    if (!pointerEnabled && isFocused) {
      focusManager.changeFocus(returnParent || nav.parent);
    }
  }, [nav.parent, returnParent, pointerEnabled, isFocused]);

  const enabled = pointerEnabled && enabledProp;

  if (!pointerSupported || (!enabled && !isFocused && alwaysRender === false)) {
    return null;
  }

  let Icon;
  let iconClassName;
  if (left === true) {
    Icon = IconLeft;
    iconClassName = classNames(componentTheme.icon, componentTheme.iconLeft);
  } else if (right === true) {
    Icon = IconRight;
    iconClassName = classNames(componentTheme.icon, componentTheme.iconRight);
  } else if (up === true) {
    Icon = IconUp;
    iconClassName = componentTheme.icon;
  } else if (down === true) {
    Icon = IconDown;
    iconClassName = componentTheme.icon;
  }

  /**
   * using RoundButton instead of FocusRoundButton,
   * but RoundButton is still using focus nav,
   * so using nav to manipulate RoundButton state
   */
  const buttonNav = {
    onMouseOut: nav.onMouseOut,
    onMouseOver: nav.onMouseOver,
    // only allow button to display as focused when arrow is enabled
    isFocused: enabled && isFocused
  };

  const height = isNumber(heightProp) ? vw(heightProp) : heightProp;
  const width = isNumber(widthProp) ? vw(widthProp) : widthProp;

  /*
   * component can render as enabled, as focused or enabled + focused.
   * component will be focused and not enabled after scrolling to end.
   * css will reflect state based on enabled and focused classes.
   * arrow button is only displayed as focused when arrow is enabled.
   */

  /*
   * container div is used for size and so button is positioned
   * inside container.
   * both button and width/height area can be clicked.
   * click area div is separate because if onClick is placed on
   * both container div and button then event can trigger for
   * both when clicking button.
   * onClick is used for both button and click area to allow
   * button to overlap the edge of the click area and still be
   * clickable.
   */

  return (
    <div
      {...props}
      style={{
        width,
        height,
        ...style
      }}
      className={classNames(
        componentTheme.navigationArrow,
        theme?.navigationArrow,
        className,
        {
          [componentTheme.enabled]: enabled,
          [theme?.enabled]: enabled,
          [componentTheme.left]: left,
          [theme?.left]: left,
          [componentTheme.right]: right,
          [theme?.right]: right,
          [componentTheme.up]: up,
          [theme?.up]: up,
          [componentTheme.down]: down,
          [theme?.down]: down,
          [componentTheme.focused]: isFocused,
          [theme?.focused]: isFocused
        }
      )}
    >
      <RoundButton
        onClick={onClick}
        Icon={Icon}
        iconClassName={iconClassName}
        nav={buttonNav}
        className={classNames(componentTheme.arrowButton, theme?.arrowButton)}
        isFocused={buttonNav.isFocused}
      />
      <div
        style={{
          width,
          height
        }}
        className={classNames(
          componentTheme.navigationArrow,
          theme?.navigationArrow,
          {
            [componentTheme.enabled]: enabled,
            [theme?.enabled]: enabled
          }
        )}
        onMouseOut={nav.onMouseOut}
        onMouseOver={nav.onMouseOver}
        onClick={onClick}
        role="button"
        aria-label={label}
      />
    </div>
  );
};

NavigationArrow.propTypes = {
  /** whether the Arrow is an up arrow */
  up: PropTypes.bool,
  label: PropTypes.string,
  /** whether the Arrow is an down arrow */
  down: PropTypes.bool,
  /** whether the Arrow is an left arrow */
  left: PropTypes.bool,
  /** whether the Arrow is an right arrow */
  right: PropTypes.bool,
  /** always rendered */
  alwaysRender: PropTypes.bool,
  /** width of the container */
  width: PropTypes.any,
  /** height of the container */
  height: PropTypes.any,
  className: PropTypes.string,
  /** theme object from css module */
  theme: PropTypes.object,
  returnParent: PropTypes.string,
  /** nav object from @accedo/vdkweb-navigation */
  nav: PropTypes.object,
  /** whether the Arrow enabled by default */
  enabled: PropTypes.bool,
  /** whether the Arrow is focused by default */
  isFocused: PropTypes.bool,
  style: PropTypes.object,
  /** Functiona what will be executed when the user interact with the Arrow. */
  onClick: PropTypes.func
};

const FocusNavigationArrow = withFocus(NavigationArrow);

export { NavigationArrow, FocusNavigationArrow };
