import { useContext, useCallback, useMemo } from 'react';
import {
  ExtraClassesContext,
  ExtraClassesSetContext
} from '#/context/ExtraStyleClassesProvider';

const useExtraStyleClasses = () => {
  const extraClassesContext = useContext(ExtraClassesContext);
  const setExtraClassesContext = useContext(ExtraClassesSetContext);

  const addExtraClass = useCallback(
    className => {
      const extraClasses = extraClassesContext;
      const updateExtraClass = setExtraClassesContext;
      const newExtraClasses = extraClasses;
      if (!extraClasses?.includes(className)) {
        newExtraClasses.push(className);
      }
      updateExtraClass(newExtraClasses);
    },
    [extraClassesContext, setExtraClassesContext]
  );

  const removeExtraClass = useCallback(
    className => {
      const extraClasses = extraClassesContext;
      const updateExtraClass = setExtraClassesContext;
      let newExtraClasses = extraClasses;
      newExtraClasses = extraClasses.filter(
        extraClass => extraClass !== className
      );
      updateExtraClass(newExtraClasses);
    },
    [extraClassesContext, setExtraClassesContext]
  );

  const extraClassesMemo = useMemo(() => {
    const extraClasses = extraClassesContext;
    return extraClasses;
  }, [extraClassesContext]);

  return { extraClasses: extraClassesMemo, addExtraClass, removeExtraClass };
};

export default useExtraStyleClasses;
