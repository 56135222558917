import MediaContent from '#/interfaces/MediaContent';

/**
 * @description
 * Use favourite builder to modify values on a favourite instance.
 * @constructor
 * @param {string} mediaContent - A media content (Ex: Live, Movie, Series or Episode).
 */
class FavouriteBuilder<T extends MediaContent> {
  mediaContent: T;

  constructor(mediaContent: T) {
    this.mediaContent = mediaContent;
  }

  /**
   * Prioritize images in order of relevance for each type of media content.
   * @returns {FavouriteBuilder<T>} "this" - Current FavouriteBuilder instance.
   */
  sortImages() {
    const newImages = [
      {
        url:
          (this.mediaContent as any)?.poster_path ||
          (this.mediaContent as any)?.coverUrl ||
          (this.mediaContent as any)?.channel?.url
      }
    ];

    if (newImages[0].url) {
      newImages[0].url = newImages[0].url.replace(/^http:/, 'https:');
    }

    this.mediaContent.images = newImages;
    return this;
  }

  /**
   * Define a type fallback.
   * @returns {FavouriteBuilder<T>} "this" - Current FavouriteBuilder instance.
   */
  setTypeFallback() {
    this.mediaContent.type =
      this.mediaContent.type || (this.mediaContent as any)?.media_type;
    return this;
  }

  /**
   * Return/create the final MediaContent object.
   * @returns {MediaContent} "this" - Current FavouriteBuilder instance.
   */
  create(): T {
    const newMediaContent: T = { ...this.mediaContent };
    return newMediaContent;
  }
}

export default FavouriteBuilder;
