import React from 'react';
import PropTypes from 'prop-types';
import useI18n from '#/hooks/useI18n';
import * as i18n from '#/services/i18n';
import styles from '../views.scss';

const FailedToLoad = ({ debugInfo }) => {
  const { currentLocale = {} } = useI18n();
  const { dir } = currentLocale;
  return (
    <div className={`${styles.failedPageContent} ${styles[dir]}`} dir={dir}>
      <h2>{i18n.getTranslation('detail.loadErrorTitle')}</h2>
      <div>{i18n.getTranslation('detail.loadErrorMessage')}</div>
      {debugInfo ? (
        <div>
          <h4>Debug Info</h4>
          <div>{JSON.stringify(debugInfo)}</div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

FailedToLoad.propTypes = {
  debugInfo: PropTypes.object
};

export default FailedToLoad;
