import React, { useEffect } from 'react';

import { focusManager } from '@accedo/vdkweb-navigation';

import Popup from '#/components/Popup/Popup';
import { getTranslation } from '#/services/i18n';
import useApiError from '#/hooks/useApiError';
import useHistoryBack from '#/hooks/history/useHistoryBack';
import { navIdMap } from '#/utils/navigationHelper';

const NAV_IDS = navIdMap;

const ErrorPopup = () => {
  const { error, removeError } = useApiError();
  const historyBack = useHistoryBack();
  useEffect(() => {
    if (error) {
      focusManager.changeFocus(NAV_IDS.POPUP.GLOBAL_ERROR_POPUP);
    }
  }, [error]);

  if (!error) {
    return <div />;
  }

  return (
    <Popup
      nav={{
        id: NAV_IDS.POPUP.GLOBAL_ERROR_POPUP
      }}
      title={<span>{getTranslation('loadErrorTitle')}</span>}
      content={<span>{getTranslation('loadErrorMessage')}</span>}
      isOpen={error}
      onAccept={() => {
        removeError();
        historyBack();
      }}
    />
  );
};

export default ErrorPopup;
