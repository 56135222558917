import Info from '#/interfaces/Info';

const InfoModel = (rawContent: any): Info => {
  const { content } = rawContent;

  const getContent = (): string => {
    return content;
  };

  return {
    content: getContent()
  };
};

class InfoModelBuilder<T> {
  rawContent: T;

  constructor(rawContent: T) {
    this.rawContent = rawContent;
  }

  /**
   * Return/create the final Info object.
   * @returns {Info} "this" - Current Info instance.
   */
  create(): Info {
    return InfoModel(this.rawContent);
  }
}

export default InfoModelBuilder;
