import React from 'react';
import useI18n from '#/hooks/useI18n';
import styles from './skeletons.scss';

// CarouselSkeleton returns an empty landing page carousel to simulate loading.
const HeroSkeleton = () => {
  const { currentLocale = {} } = useI18n();
  const { dir } = currentLocale;
  return (
    <div className={`${styles.heroSkeleton} ${styles[dir]}`} dir={dir}>
      <br />
      <div className={`${styles.contentCarousel} ${styles[dir]}`}>
        <div className={`${styles.bannerContent} ${styles.baseColor}`} />
        <div className={`${styles.button} ${styles.baseColor}`} />
      </div>
    </div>
  );
};

export default HeroSkeleton;
