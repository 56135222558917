import { assetTypes } from '#/models/assetTypes';
import favouriteProvider from '#/providers/favourite';
import { favoritesAdd, favoritesRemove } from '#/services/analytics';
import { mapToArray } from '#/utils/dataConverterHelper';
import FavouriteBuilder from '#/models/favourite/favourite';
import { Movie } from '#/interfaces/Movie';
import { Show } from '#/interfaces/Show';
import Live from '#/interfaces/live/Channel';
import MediaContent from '#/interfaces/MediaContent';

const { get, set, clear } = favouriteProvider;
/**
 * Represents favorite service.
 * @constructor
 * @param {string} userIdentifier - Unique user identifier. It will be used as a favorite index.
 */
class FavouriteService {
  userIdentifier: string;

  constructor(userIdentifier: string) {
    if (!userIdentifier) {
      throw new Error('FavouriteService - userIdentifier is required.');
    }

    this.userIdentifier = userIdentifier;
  }

  /**
   * Add an entry to the Favourite store.
   * @example
   * addFavourite('star-wars', { title: 'Star Wars' }).then(added => refreshFavourites(added))
   * @param {String} key ContentId
   * @param {any} content Content (Ex: Movie, Series, TVShow object)
   * @returns {Promise<boolean>} Promise - True or False, where True indicates that the content has been added to favourites and False indicates that something it wrong.
   */
  addFavourite = async (key: string, content: any): Promise<boolean> => {
    let currentFavourites: Map<string, any> = await get(this.userIdentifier);

    if (!currentFavourites) {
      currentFavourites = new Map<string, any>();
    }

    currentFavourites.set(key, content);

    set(this.userIdentifier, currentFavourites);
    favoritesAdd(content);
    return Promise.resolve(true);
  };

  /**
   * Remove an entry from the Favourite store.
   * @example
   * removeFavourite('star-wars').then(removed => refreshFavourites(removed))
   * @param {Object} content Item to be removed
   * @param {String|Number} content.id Favourite item to be removed
   * @returns {Promise<boolean>} Promise with a flag that indicates
   * the success for removing an entry to the Favourite storage
   */
  removeFavourite = async (content: any) => {
    const currentFavourites: Map<string, any> = await get(this.userIdentifier);
    currentFavourites.delete(content.id);
    set(this.userIdentifier, currentFavourites);
    favoritesRemove(content);
    return Promise.resolve(true);
  };

  /**
   * Clear the Favourite store.
   * @example
   * // Assuming refreshFavourites is your logic
   * clearFavourite().then(clean => refreshFavourites(clean))
   * @returns {Promise<boolean>} Promise with a flag that indicates
   * the success for cleaning the Favourite storage
   */
  clearFavourite = async (): Promise<boolean> => {
    await clear(this.userIdentifier);
    return Promise.resolve(true);
  };

  /**
   * Get Favourite items from the store.
   * @example
   * // Assuming refreshFavourites is your logic
   * getFavourites().then(favorites => refreshFavourites(favorites))
   * @returns {Promise<T[]>} Promise - Favourites items.
   */
  getFavourites = async <T extends MediaContent>(): Promise<T[]> => {
    const favouritesMap = await get<T>(this.userIdentifier);
    const favourites: T[] = mapToArray(favouritesMap);
    return Promise.resolve(favourites);
  };

  /**
   * Check if a content has been favourited.
   * @example
   * // Assuming setStared is your logic
   * isFavourite('star-wars').then(favorited => setStared(favourited))
   * @param {String|Number} key Favourite item to be removed
   * @returns {Promise<boolean>} Promise with a flag that indicates if a
   * specific key is stored on Favourite storage
   */
  isFavourite = async (key: string): Promise<boolean> => {
    const favouritesMap: Map<string, any> = await get(this.userIdentifier);
    return Promise.resolve(favouritesMap.has(key));
  };

  /**
   * Retrieves data by type from the favourites entries.
   * @private
   * @param {any[]} favorites The favourite entries to filter
   * @param {string} type Type of asset
   * @returns {any[]} favourites of the specified type
   */
  static _getDataByType = <T extends MediaContent>(
    favorites: T[],
    type: string
  ): T[] => {
    return favorites.filter(i => i.type === type);
  };

  /**
   * Gets arrays of favourite items by type.
   * @returns {Promise<any>} Promise with favourite items filtered by type
   */
  getAllFavouritesByType = async () => {
    const favouritesFromStorage = await this.getFavourites<any>();

    const moviesFromStorage = FavouriteService._getDataByType<Movie>(
      favouritesFromStorage,
      assetTypes.MOVIE
    );
    const episodesFromStorage = FavouriteService._getDataByType<Movie>(
      favouritesFromStorage,
      assetTypes.EPISODE
    );
    const showsFromStorage = FavouriteService._getDataByType<Show>(
      favouritesFromStorage,
      assetTypes.TVSHOW
    );
    const channelsFromStorage = FavouriteService._getDataByType<Live>(
      favouritesFromStorage,
      assetTypes.LIVE_CHANNEL
    );

    const movies = moviesFromStorage.map(movie =>
      new FavouriteBuilder<Movie>(movie)
        .sortImages()
        .setTypeFallback()
        .create()
    );

    const episodes = episodesFromStorage.map(episode =>
      new FavouriteBuilder<Movie>(episode)
        .sortImages()
        .setTypeFallback()
        .create()
    );

    const shows = showsFromStorage.map(show =>
      new FavouriteBuilder<Show>(show)
        .sortImages()
        .setTypeFallback()
        .create()
    );

    const channels = channelsFromStorage.map(channel =>
      new FavouriteBuilder<Live>(channel)
        .sortImages()
        .setTypeFallback()
        .create()
    );

    return { movies, episodes, shows, channels };
  };
}

export default FavouriteService;
