/**
 * Check to see if the input parameter is actually a number object.
 *
 * @memberof module:base/util
 * @param {Object} obj - Object to be checked.
 * @return {Boolean} True if the object is, false otherwise.
 */
const isNumber = obj =>
  Object.prototype.toString.call(obj) === '[object Number]';

export default isNumber;
