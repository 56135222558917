import { useContext, useCallback } from 'react';
import { ConfigContext, ConfigSetcontext } from '#/context/ConfigContext';
import { AuthContext } from '#/context/AuthContext';
import {
  getConfiguration,
  getDefaultTheme,
  getRoutesMapping
} from '#/services/cms';

let configRequestPending = false;
let configRequestPendingUserId = null;

const useAppConfig = () => {
  const configContext = useContext(ConfigContext);
  const setConfigContext = useContext(ConfigSetcontext);
  const authContext = useContext(AuthContext);

  if (setConfigContext === undefined || configContext === undefined) {
    throw new Error('useAppConfig must be used within a ConfigProvider');
  }

  const getConfigData = useCallback(
    async userId => {
      if (configRequestPending && configRequestPendingUserId === userId) {
        return;
      }
      configRequestPending = true;
      configRequestPendingUserId = userId;
      let appConfig = {};
      try {
        appConfig = await getConfiguration(userId);
        const theme = await getDefaultTheme();
        const routesMapping = await getRoutesMapping();
        appConfig.theme = theme;
        appConfig.routesMapping = routesMapping;
        configRequestPending = false;
        setConfigContext({ config: appConfig });
        return appConfig;
      } catch (error) {
        const config = { errorLoading: true };
        setConfigContext({ config });
        return config;
      }
    },
    [setConfigContext]
  );

  const getConfig = useCallback(() => {
    const { config } = configContext;
    const { userId } = authContext?.getUser?.() || {};
    if (!config) {
      getConfigData(userId);
      return {};
    }
    return config;
  }, [getConfigData, configContext, authContext]);

  return {
    config: getConfig()
  };
};

export default useAppConfig;
