import { KeyboardLayouts } from '@accedo/vdkweb-tv-ui';
import {
  dotcomButton,
  dotnetButton,
  layoutSymbolsActiveButton,
  layoutLettersButton,
  uppercaseSingleButton,
  backspaceSingleButton,
  layoutSymbolsButton,
  layoutLettersActiveButton,
  spaceFourspaceButton
  // clearDoublespaceButton
} from '@accedo/vdkweb-tv-ui/lib/FocusKeyboard/layouts/sharedSpecialKeys';
import {
  textArray as ogTextArray,
  FEATURE_KEY as OG_FEATURE_KEY
} from '@accedo/vdkweb-tv-ui/lib/FocusKeyboard/layouts/en/defaultLayout';
import {
  textArray as ogSymbolTextArray,
  FEATURE_KEY as OG_SIMBOL_FEATURE_KEY
} from '@accedo/vdkweb-tv-ui/lib/FocusKeyboard/layouts/en/symbol';
import { symbol } from 'prop-types';

const { en } = KeyboardLayouts;

const noSpecialDefault = en.default.textArray.slice(
  0,
  en.default.textArray.length - Object.keys(en.default.FEATURE_KEY).length
);
const noSpecialSymbol = ogSymbolTextArray.slice(
  0,
  ogSymbolTextArray.length - Object.keys(OG_SIMBOL_FEATURE_KEY).length
);

// const CHAR_CODE_A_LOWER = 97;
// const CHAR_CODE_ZERO = 48;
// const CHAR_CODE_ONE = 49;

const FEATURE_KEY = {
  UPPERCASE: uppercaseSingleButton,
  BACKSPACE: backspaceSingleButton,
  LAYOUT2: {
    ...layoutSymbolsButton,
    themeClass: 'buttonTripleSizeContainer',
    columns: 3
  },
  SPACE: spaceFourspaceButton,
  LAYOUT1: {
    ...layoutLettersActiveButton,
    themeClass: 'buttonTripleSizeContainer',
    columns: 3
  }
  // CLEAR: clearDoublespaceButton
};

const FEATURE_KEY_SYMBOL = {
  DOTCOM: dotcomButton,
  DOTNET: dotnetButton,
  UPPERCASE: uppercaseSingleButton,
  BACKSPACE: backspaceSingleButton,
  LAYOUT2: {
    ...layoutSymbolsActiveButton,
    themeClass: 'buttonTripleSizeContainer',
    columns: 3
  },
  SPACE: spaceFourspaceButton,
  LAYOUT1: {
    ...layoutLettersButton,
    themeClass: 'buttonTripleSizeContainer',
    columns: 3
  }
  // CLEAR: clearDoublespaceButton
};

const featureKeyDefaultTxt = Object.entries(FEATURE_KEY).map(obj => {
  return obj[1].text;
});
const textArray = noSpecialDefault.concat(featureKeyDefaultTxt);

const featureKeySymbolTxt = Object.entries(FEATURE_KEY_SYMBOL).map(obj => {
  return obj[1].text;
});
const textArraySymbol = noSpecialSymbol.concat(featureKeySymbolTxt);

// const textArrayUpper = textArray.map(txt => {
//   if (featureKeyTxt.includes(txt)) {
//     return txt;
//   }

//   return txt.toUpperCase();
// });

ogTextArray.length = 0;
delete OG_FEATURE_KEY.CLEAR;
Object.keys(FEATURE_KEY).forEach(key => {
  OG_FEATURE_KEY[key] = FEATURE_KEY[key];
});
textArray.forEach(key => {
  ogTextArray.push(key);
});

ogSymbolTextArray.length = 0;
delete OG_SIMBOL_FEATURE_KEY.CLEAR;
Object.keys(FEATURE_KEY_SYMBOL).forEach(key => {
  OG_SIMBOL_FEATURE_KEY[key] = FEATURE_KEY_SYMBOL[key];
});
textArraySymbol.forEach(key => {
  ogSymbolTextArray.push(key);
});

export const customLayout = {
  ...en,
  default: { textArray, FEATURE_KEY },
  symbol: { textArray: textArraySymbol, FEATURE_KEY: FEATURE_KEY_SYMBOL }
  // upper: { textArray: textArrayUpper, FEATURE_KEY }
};
