import enDictionary from './dictionary.en';

export default {
  en: {
    name: 'English Dictionary',
    directionality: 'ltr',
    ...enDictionary
  },
  es: {
    name: 'Spanish Dictionary',
    directionality: 'ltr',
    key: 'valor',
    interpolatedKey: 'value {{name}}'
  },
  ar: {
    name: 'Arabic Dictionary',
    directionality: 'rtl',
    key: 'value',
    interpolatedKey: 'value {{name}}'
  }
};
