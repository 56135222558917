import { get as _get } from 'lodash';
import isRTL from '#/utils/isRTL';

export const Direction = {
  Horizontal: 'horizontal',
  Vertical: 'vertical'
};

const findNextSibling = ({
  current = 0,
  components = [],
  direction = 1,
  prefix = ''
}) => {
  let index = current + direction;

  while (components[index]) {
    if (!_get(components[index], 'skipNavigation')) {
      return _get(components, `${index}.props.nav.id`, `${prefix}${index}`);
    }

    index += direction;
  }

  return undefined;
};

export const addNavToItems = ({
  direction,
  loop,
  components = [],
  parentNav = {},
  dir
} = {}) => {
  const filteredComponents = components.filter(component => component);
  const vertical = direction === Direction.Vertical;
  const rtl = isRTL(dir);
  const prevDirection = vertical ? 'nextup' : rtl ? 'nextright' : 'nextleft';
  const nextDirection = vertical ? 'nextdown' : rtl ? 'nextleft' : 'nextright';
  const { id } = parentNav;
  const childIdPrefix = `${id}-CHILD-`;
  const firstLoopItem = loop ? `${childIdPrefix}0` : '';
  const lastLoopItem = loop
    ? `${childIdPrefix}${filteredComponents.length - 1}`
    : '';
  const prevItem = parentNav[prevDirection] || lastLoopItem;
  const nextItem = parentNav[nextDirection] || firstLoopItem;

  return filteredComponents.map((component, index) => {
    const { skipNavigation, props: { nav = {} } = {} } = component;

    const params = {
      current: index,
      components: filteredComponents,
      prefix: childIdPrefix
    };

    const nextSibling = findNextSibling(params);
    const prevSibling = findNextSibling({ ...params, direction: -1 });
    const defaultNav = {
      ...nav,
      parent: id,
      id: _get(components, `${index}.props.nav.id`, `${childIdPrefix}${index}`)
    };

    if (skipNavigation) {
      return {
        ...component,
        nav: { ...defaultNav }
      };
    }

    return {
      ...component,
      nav: {
        ...defaultNav,
        [prevDirection]: index > 0 ? prevSibling : prevItem,
        [nextDirection]:
          index < filteredComponents.length - 1 ? nextSibling : nextItem
      }
    };
  });
};
