import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as xdk from '@accedo/xdk-core';

const useNetworkStatus = () => {
  const [networkStatus, setNetworkStatus] = useState();

  const getNetworkStatus = useCallback(
    () =>
      xdk.device.system
        ?.getNetwork()
        .getConnectionStatus()
        .then(status => {
          setNetworkStatus(status);
          return status;
        }),
    []
  );

  useEffect(() => {
    /**
     * initial status
     */
    getNetworkStatus();

    /**
     * react to change
     */

    const listener = status => {
      setNetworkStatus(status);
    };
    xdk.environment.addEventListener(
      xdk.environment.SYSTEM.NETWORK_STATUS_CHANGED,
      listener
    );
    return () => {
      xdk.environment.removeEventListener(
        xdk.environment.SYSTEM.NETWORK_STATUS_CHANGED,
        listener
      );
    };
  }, [getNetworkStatus]);

  return {
    networkStatus
  };
};

useNetworkStatus.propTypes = {
  handleError: PropTypes.func
};

export default useNetworkStatus;
