/**
 * @module services/query
 * @description
 * Service to fetch data to the OVP using queries
 */
import ovp from '#/providers/ovp';
import Item from '#/models/item/item';
import { PROVIDER_TYPE } from '#/config/constants';
import * as ovpAmagi from '#/providers/ovp/amagi/amagi';
import amagiLiveOvpV2 from '#/providers/ovp/amagi/amagi_live_v2';
import fetcher from '../../helpers/fetcher';

/**
 * @param {String} query The query for the OVP.
 * @param {String} contextData Context for being interpolated with the query.
 * @param {Number} from Init index for retriving data.
 * @param {Number} to Final index for retriving data.
 * @param {Number} itemsPerPage Max number of item per query.
 * @param {String} sortBy Key for ordering data.
 * @returns {Array} Array of Items
 */

const getItemsByQuery = async ({
  query,
  pageNumber,
  contextData,
  itemsPerPage,
  sortBy = ''
}) => {
  // Match with '{{something}}'
  const parameters = query?.match(/[^{}]+(?=})/g);
  let interpolatedQuery = query;

  parameters?.forEach(parameter => {
    if (parameter && contextData && contextData[parameter]) {
      const value = contextData[parameter];
      interpolatedQuery = interpolatedQuery.replace(
        new RegExp(`{{${parameter}}}`),
        () => value.toLowerCase()
      );
    }
  });

  const result = await fetcher({
    cacheId: `${PROVIDER_TYPE.ovp}-query-${interpolatedQuery}-${pageNumber}`,
    fetchFn: () =>
      ovp.getItemsByQuery({
        query: interpolatedQuery,
        pageNumber,
        sortBy,
        itemsPerPage
      })
  });

  if (!result) {
    console.warn(`[debug] Not response from query: ${query}`);
  }

  return {
    items: result?.items?.map(item => Item(item)),
    total: result?.total
  };
};

const getItemsByQueryAmagi = async ({
  query,
  pageNumber,
  contextData,
  itemsPerPage,
  ...props
}) => {
  // Match with '{{something}}'
  const parameters = query?.match(/[^{}]+(?=})/g);
  let interpolatedQuery = query;

  parameters?.forEach(parameter => {
    if (parameter && contextData && contextData[parameter]) {
      const value = contextData[parameter];
      interpolatedQuery = interpolatedQuery.replace(
        new RegExp(`{{${parameter}}}`),
        () => value.toLowerCase()
      );
    }
  });

  const result = await fetcher({
    cacheId: `${PROVIDER_TYPE.amagi}-query-${interpolatedQuery}${
      props.userId ? `-${props.userId}` : ''
    }-${pageNumber}`,
    fetchFn: () =>
      ovpAmagi.getItemsByQuery({
        query: interpolatedQuery,
        pageNumber,
        itemsPerPage,
        ...props
      })
  });

  if (!result) {
    console.warn(`[debug][Amagi] Not response from query: ${query}`);
  }

  return {
    items: result?.items?.map(item => Item(item)),
    total: result?.total
  };
};

const getLiveAmagiItemsByQuery = async ({
  query,
  pageNumber,
  contextData,
  itemsPerPage,
  template,
  userId,
  sortBy = ''
}) => {
  const splitQueryList = query.split('?');
  const isEvent = splitQueryList?.[0]?.includes('events');
  const queryParamsList = isEvent
    ? splitQueryList[1]?.split('&')
    : query?.split('&');
  const queryParams = { template, userId };
  queryParamsList?.forEach(param => {
    const [paramKey, paramValue] = param.split('=');
    queryParams[paramKey] = paramValue;
  });

  let result;

  if (isEvent) {
    result = await amagiLiveOvpV2.getLiveEventsListing(queryParams);
  } else {
    result = await amagiLiveOvpV2.getTvListings(queryParams);
  }

  if (!result) {
    console.warn(`[debug] Not response from query: ${query}`);
  }

  return {
    items: result,
    total: result?.length
  };
};

export { getItemsByQuery, getItemsByQueryAmagi, getLiveAmagiItemsByQuery };
