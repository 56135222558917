import React from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withFocus } from '@accedo/vdkweb-navigation';
import { SearchIcon, UserIcon, SettingsIcon } from '#/components/Icons/Icons';
import style from './menuItem.scss';

const icons = {
  search: ({ className }) => <SearchIcon className={className} />,
  profile: ({ className }) => (
    <UserIcon className={`${className} ${style.user}`} />
  ),
  settings: ({ className }) => <SettingsIcon className={className} />
};

/**
 * MenuItem Component
 * @param {Object} props Component Props
 * @param {Function} props.onClick Callback function to dispatch when
 * @param {Object} props.data The content to be displayed
 * @returns {React.ReactComponentElement} Component
 */
const MenuItem = ({ onClick, data, nav }) => {
  const { pathname } = useLocation();

  const { image, itemGroup, viaIconLabel } = data;

  const userImg = image ? (
    <img className={style.profilePicture} src={image} alt="" />
  ) : null;

  const className =
    itemGroup === 'primary'
      ? `${style.item} ${style.primary}`
      : `${style.item} ${style.secondary}`;

  const itemIcon =
    viaIconLabel && icons[viaIconLabel]
      ? icons[viaIconLabel]({
          className: `${className} ${style.icon}`,
          focused: nav.isFocused
        })
      : null;

  const itemContent = userImg || itemIcon || data.displayText;

  const focusedStyle = nav.isFocused ? style.textUnderline : '';

  const lastSelectedItemStyle =
    pathname === data.route ? style.lastSelectedButton : '';

  const itemClassName = `${style.item} ${
    nav.isFocused ? style.itemFocused : ''
  } ${
    !userImg && !itemIcon && data.displayText
      ? focusedStyle || lastSelectedItemStyle
      : style.image
  }`;

  return (
    <div
      className={itemClassName}
      onMouseOver={nav.onMouseOver}
      onMouseOut={nav.onMouseOut}
    >
      <button
        nav={nav}
        onClick={onClick}
        theme={{
          buttonFocused: style.buttonFocused
        }}
      >
        {itemContent}
      </button>
    </div>
  );
};

MenuItem.propTypes = {
  onClick: PropTypes.func,
  data: PropTypes.object,
  nav: PropTypes.object
};

export default withFocus(MenuItem);
