import React from 'react';
import AssetItem from '../AssetItem/AssetItem';

import categoryItemTheme from './categoryItem.scss';

const CategoryItem = props => (
  <AssetItem {...props} theme={categoryItemTheme} />
);

export default CategoryItem;
