/**
 * Build an unique key based on the domain and key.
 * @param {string} domainAlias Identify a context/collection/domain in the storage. Ex: Favourite, Bookmark, etc.
 * @param {string} profileId Unique profileId. Identify the user in the database.
 * @returns {String} Storage key
 */
export const buildStorageKey = (
  domainAlias: string,
  profileId: string
): string => {
  return `${domainAlias}__${profileId}`;
};
