import ovp from '#/providers/ovp';
import { PROVIDER_TYPE } from '#/config/constants';
import Season from '#/models/season/season';
import fetcher from '../../helpers/fetcher';

/**
 * @module services/season
 * @description
 * Service to handle the fetch of season data
 */

/**
 * Season Services
 * @param {String} id TVShow ID
 * @returns {Promise<Array<any>>} Seasons
 */
const getSeasons = async id => {
  const rawSeasons = await fetcher({
    cacheId: `${PROVIDER_TYPE.ovp}-show-seasons-${id}`,
    fetchFn: () => ovp.getTvShowSeasonsById(id)
  });
  return rawSeasons?.entries?.map(rawSeason => Season(rawSeason));
};

/**
 * Get a single Season
 * @param {String} seasonId Season ID
 * @param {String} tvShowId TVShow ID
 * @returns {Promise<Object>} Season
 */
const getSeason = async (seasonId, tvShowId) => {
  const rawSeasons = await getSeasons(tvShowId);
  const tvSeason =
    rawSeasons?.length && rawSeasons?.find(season => season.id === seasonId);
  return Season(tvSeason);
};

export { getSeasons, getSeason };
