import React from 'react';

import { Direction } from './addNavToItems';
import Layout from './Layout';

const VerticalLayout = props => (
  <Layout {...props} direction={Direction.Vertical} />
);

export default VerticalLayout;
