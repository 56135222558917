import { useEffect } from 'react';
import { environment } from '@accedo/xdk-core';

const useKeyHandler = handler => {
  useEffect(() => {
    environment.addEventListener(environment.SYSTEM.KEYDOWN, handler);

    // Remove event listener on cleanup
    return () => {
      environment.removeEventListener(environment.SYSTEM.KEYDOWN, handler);
    };
  }, [handler]);
};

export default useKeyHandler;
