/**
 * @module models/CMS
 */

/**
 * This model represents the Config.
 *
 * vikiElevateConfig representation
 * @typedef {Object} VikiElevateConfig
 * @property {String} title The App's title
 * @property {String} ovpUrl The OVP url
 * @property {Boolean} copyright The copyright message
 * @property {String} status The application status
 * @property {String} menuId The main menu ID in the CMS
 * @property {String} appLogo The app logo url
 * @property {String} defaultLocale The default locale code
 * @property {String} dictionary The app dictionary
 * @property {Number} ovpPageSize The default value for the Page Size for OVP requests
 * @property {String} routesMapping The app Route Mapper
 */

/**
 * VikiElevateConfig Model
 * @param {Object} rawConfig Config
 * @returns {VikiElevateConfig} config - The {@link VikiElevateConfig} representation
 */
const config = rawConfig => {
  const {
    assets,
    copyright,
    title,
    status,
    mainMenuEntryId,
    defaultLocale,
    dictionary,
    ovpPageSize,
    routesMapping,
    integrations
  } = rawConfig;

  const ovpUrl = 'https://accedoovp20x.ocs.ps.accedo.tv';

  /**
   * Get the App title
   * @returns {String} title
   */
  const getTitle = () => {
    return title;
  };
  /**
   * Get the ovp url
   * @returns {String} accedoOvpUrl
   */
  const getOvpUrl = () => {
    return ovpUrl;
  };
  /**
   * Get the copyright message
   * @returns {String} copyright
   */
  const getCopyRight = () => {
    return copyright;
  };
  /**
   * Get the app status
   * @returns {String} status
   */
  const getStatus = () => {
    return status;
  };
  /**
   * Get the app status
   * @returns {String} status
   */
  const getMenuId = () => {
    return mainMenuEntryId;
  };
  /**
   * Get the app status
   * @returns {String} status
   */
  const getAppLogo = () => {
    if (assets) {
      return assets.appLogo;
    }
  };
  /**
   * Get the app default locale
   * @returns {String} defaultLocale
   */
  const getDefaultLocale = () => {
    return defaultLocale;
  };
  /**
   * Get the app dictionary
   * @returns {String} dictionary
   */
  const getDictionary = () => {
    return dictionary;
  };

  /**
   * Get the OVP page size for queries
   * @returns {number} ovpPageSize
   */
  const getOvpPageSize = () => {
    return ovpPageSize;
  };

  /**
   * Get the Route Mapper Entry id
   * @returns {string} routesMapping
   */
  const getRoutesMapping = () => {
    return routesMapping;
  };

  const getIntegrations = () => {
    return integrations;
  };

  return {
    ovpUrl: getOvpUrl(),
    ovpPageSize: getOvpPageSize(),
    copyright: getCopyRight(),
    status: getStatus(),
    title: getTitle(),
    menuId: getMenuId(),
    appLogo: getAppLogo(),
    defaultLocale: getDefaultLocale(),
    dictionary: getDictionary(),
    routesMapping: getRoutesMapping(),
    integrations: getIntegrations()
  };
};

export default config;
