import fetch from '@accedo/vdkweb-fetch';
import fetcher from '#/services/helpers/fetcher';

const AMAGI_OVP = 'https://a9errhatwi.execute-api.us-east-1.amazonaws.com/v1/';
const AMAGI_EPG_ALL =
  'https://a9errhatwi.execute-api.us-east-1.amazonaws.com/v1/epg/all';
const AMAGI_CHANNELS =
  'https://a9errhatwi.execute-api.us-east-1.amazonaws.com/v1/channels';
const AMAGI_EPG_GUIDE_WINDOW =
  'https://a9errhatwi.execute-api.us-east-1.amazonaws.com/v1/epg/guide-window';

const getChannelTvListings = async channel => {
  const channelsEntrycacheId = `amagi-${channel.id}-${channel.channel}`;
  const rawChannelProgramming = await fetcher({
    cacheId: channelsEntrycacheId,
    fetchFn: () => fetch(channel.epg_json, {})
  });
  const channelProgramming = await rawChannelProgramming.clone().json();

  return Promise.resolve(channelProgramming);
};

const getChannelsGuideWindow = async (queryParams = {}) => {
  const {
    template,
    when,
    genre,
    language,
    pageSize = 20,
    pageNumber = 1,
    userId
  } = queryParams;
  const now = new Date();
  let url = `${AMAGI_CHANNELS}?when=${when}`;
  url += userId ? `&userId=${userId}` : '';
  url += language ? `&language=${language}` : '';
  const cacheEntryId = `amagi-guide-window--${now.getHours()}-${now.getMinutes()}`;

  const rawGuideWindow = await fetcher({
    cacheId: cacheEntryId,
    fetchFn: () => fetch(url, {})
  });
  const guideWindow = await rawGuideWindow.clone().json();
  const guideWindowWithOrder = guideWindow.entries.map((ch, idx) => ({
    ...ch,
    order: idx
  }));
  guideWindow.entries = guideWindowWithOrder;

  return Promise.resolve(guideWindow);
};

const getChannelData = async (queryParams = {}) => {
  const channelsEntrycacheId = 'amagi-channels';
  const rawChannels = await fetcher({
    cacheId: channelsEntrycacheId,
    fetchFn: () => fetch(AMAGI_EPG_ALL, {})
  });
  const channels = await rawChannels.clone().json();
  const augmentedChannels = [];

  const channelListingPromises = [];
  channels.forEach(channel => {
    channelListingPromises.push(getChannelTvListings(channel));
  });

  const channelTvListing = await Promise.all(channelListingPromises);
  channels.forEach((channel, idx) => {
    augmentedChannels.push({
      ...channel,
      ...channelTvListing[idx].channelInfo
    });
  });

  const guideWindow = await getChannelsGuideWindow(queryParams);
  const channelsWithProgramming = augmentedChannels.map(channel => {
    const channelFromGuide = guideWindow.entries.find(
      ch => channel.id.toString() === ch.channelId
    );
    return {
      ...channel,
      programs: channelFromGuide?.programs || [],
      order:
        channelFromGuide?.order || channelFromGuide?.order === 0
          ? channelFromGuide.order
          : 99
    };
  });

  const filteredProgramming = channelsWithProgramming.filter(
    channel => channel.programs.length > 0
  );
  const sortedProgramming = filteredProgramming.sort(
    (a, b) => a.order - b.order
  );

  return Promise.resolve(sortedProgramming);
};

const getTvListings = async (queryParams = {}) => {
  const { template } = queryParams;
  const channelsProgramming = await getChannelData(queryParams);
  const isHero = template?.includes('hero');
  const now = new Date().valueOf();
  const mappedChannelsProgramming = channelsProgramming.map((channel, idx) => {
    if (isHero) {
      return {
        channelId: channel.id,
        cmsChannelId: channel.programs[0]?.contentId,
        description: channel.programs[0]?.shortDescription,
        endTime: channel.programs[0] ? channel.programs[0].endTime * 1000 : now,
        id: `${channel.programs[0]?.contentId}-${idx}`,
        images: [],
        logo: {
          fileUrl: channel.logo
        },
        poster: `https://res.cloudinary.com/accedotv/image/fetch/w_700,q_20,fl_progressive/${channel.programs[0]?.programImages?.[0].uri}`,
        startTime: channel.programs[0]
          ? channel.programs[0].startTime * 1000
          : now,
        title: channel.programs[0]?.title,
        type: 'LIVE',
        videoUrl: channel.stream_url
      };
    }

    return {
      id: `${channel.programs[0]?.contentId}-${idx}`,
      channelId: channel.id,
      logo: channel.logo,
      title: channel.name,
      startTime: channel.programs[0]
        ? channel.programs[0].startTime * 1000
        : now,
      endTime: channel.programs[0] ? channel.programs[0].endTime * 1000 : now,
      railPoster: `https://res.cloudinary.com/accedotv/image/fetch/w_1120,h_364,q_20,fl_progressive,c_crop/${channel.programs[0]?.programImages?.[0].uri}`,
      programTitle: channel.programs[0]?.title,
      videoUrl: channel.stream_url,
      type: 'live',
      queryParams
    };
  });

  return Promise.resolve(mappedChannelsProgramming);
};

const getLiveEventsListing = async (queryParams = {}) => {
  const { when, pageSize = 20, pageNumber = 1, genre, template } = queryParams;
  const isHero = template?.includes('hero');
  const now = new Date();

  let url = `${AMAGI_OVP}events?`;
  url += when ? `&when=${when}` : '';
  url += `&genre=${genre}`;
  url += pageSize ? `&pageSize=${pageSize}` : '';
  url += pageNumber ? `&pageNumber=${pageNumber}` : '';
  const cacheEntryId = `amagi-live-events-${when}-${now.getHours()}-${now.getMinutes()}`;
  const placeholderImage =
    'https://www.amagi.com/hubfs/carousel-london-fast-2024.jpg';

  const rawLiveEvents = await fetcher({
    cacheId: cacheEntryId,
    fetchFn: () => fetch(url, {})
  });
  const liveEvents = await rawLiveEvents.clone().json();
  const mappedLiveEvents = liveEvents?.entries?.map(event => {
    if (isHero) {
      return {
        endTime: event.end_time * 1000,
        id: event.id,
        poster: `https://res.cloudinary.com/accedotv/image/fetch/w_1920,h_864,q_20,fl_progressive,c_crop/${event.metadata?.find(
          meta => meta.name === 'Thumbnail'
        )?.value || placeholderImage}`,
        startTime: event.start_time * 1000,
        title: event.name,
        type: 'live',
        videoUrl:
          event.metadata?.find(meta => meta.name === 'PlaybackUrl')?.value || ''
      };
    }

    return {
      id: event.id,
      startTime: event.start_time * 1000,
      endTime: event.end_time * 1000,
      railPoster: `https://res.cloudinary.com/accedotv/image/fetch/w_1120,h_364,q_20,fl_progressive,c_crop/${event.metadata?.find(
        meta => meta.name === 'Thumbnail'
      )?.value || placeholderImage}`,
      programTitle: event.name,
      videoUrl:
        event.metadata?.find(meta => meta.name === 'PlaybackUrl')?.value || '',
      type: 'live',
      queryParams
    };
  });

  return Promise.resolve(mappedLiveEvents);
};

export default {
  getChannelData,
  getTvListings,
  getChannelTvListings,
  getLiveEventsListing
};
