import React, { useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import logger from '#/utils/logger';
import {
  getItemsByQuery,
  getItemsByQueryAmagi,
  getLiveAmagiItemsByQuery
} from '#/services/ovp';
import {
  ACCEDO_CONTROL_CONTAINER_TEMPLATES,
  CONTAINER_ID_TEMPLATE_MAP,
  CONTAINER_ITEM_TYPES,
  DEFAULT_TEMPLATE,
  ROUTES
} from '#/config/constants';
import useHistoryPush from '#/hooks/history/useHistoryPush';
import useAppConfig from '#/hooks/useAppConfig';
import { AuthContext } from '#/context/AuthContext';
import {
  CONTAINER_ID_TO_COMPONENT_MAP,
  determineItemTypeByTemplate,
  determineAligment,
  determineItemTypeByRoute
} from './templatesMapper';

const getItemOnClickFn = ({ historyPush, authContext }) => {
  return item => {
    const { type, viewAll, query, displayText, link } = item;
    const isContinueWatching = type === CONTAINER_ITEM_TYPES.Bookmark;
    const isLive = type?.toLowerCase() === CONTAINER_ITEM_TYPES.Live;
    const isNOOP = type?.toLowerCase() === CONTAINER_ITEM_TYPES.Other;

    if (isLive) {
      const { curProgram, videoUrl, programs } = item; // Cases: Channel Fav and ChannelItem from Channel Rail (ChannelShelf)
      logger.debug('Redirect to: player');
      // [AMAGI][ALWAYS FREE TO WATCH]
      // Removed for the Amagi DEMO. Temporally we should turn it free to watch.
      //
      // if (authContext.isAuthenticated) {
      historyPush({
        path: ROUTES.player,
        state: {
          asset: curProgram || programs[0],
          isLive: true,
          videoUrl: videoUrl || curProgram.videoUrl
        }
      });
      // } else {
      //   historyPush({
      //     path: ROUTES.login,
      //     state: {
      //       redirection: ROUTES.player,
      //       data: {
      //         asset: curProgram,
      //         isLive: true,
      //         videoUrl: videoUrl || curProgram.videoUrl
      //       }
      //     }
      //   });
      // }
    } else if (isContinueWatching) {
      logger.debug('Redirect to: player');
      historyPush({
        path: ROUTES.player,
        state: {
          asset: item,
          resumeTime: item.resumeTime,
          episodes: item?.seasonEpisodes,
          seasons: item?.seriesSeasons,
          videoUrl: item.videoUrl
        }
      });
    } else if (viewAll) {
      // view all
      historyPush({
        path: ROUTES.viewAll,
        state: {
          query,
          displayText,
          pageTitle: displayText
        }
      });
    } else if (isNOOP) {
      logger.debug('[debug] Asset of type "other" is NOOP');
    } else {
      logger.debug(`Redirect to: ${link}`);
      historyPush({ path: link });
    }
  };
};

const Shelf = ({
  contextData,
  items: defaultItems,
  query,
  template,
  config,
  nav,
  removeEmptyContainers
}) => {
  const { config: appConfig = {} } = useAppConfig();

  const historyPush = useHistoryPush();
  const { pathname } = useLocation();

  if (!CONTAINER_ID_TEMPLATE_MAP[template]) {
    logger.warn(`Template "${template}" is not supported`);
  }

  const containerId =
    CONTAINER_ID_TEMPLATE_MAP[template] ||
    CONTAINER_ID_TEMPLATE_MAP[DEFAULT_TEMPLATE];

  const ShelfComponent =
    CONTAINER_ID_TO_COMPONENT_MAP[containerId] ||
    CONTAINER_ID_TO_COMPONENT_MAP.Default;

  const itemType =
    determineItemTypeByRoute(pathname, template) ||
    determineItemTypeByTemplate(template);
  const aligment = determineAligment(template);

  const authContext = useContext(AuthContext);
  const userId = authContext.getUser()?.userId;

  const dataSource = useMemo(() => {
    let totalNumber;
    const hasViewAll =
      template === ACCEDO_CONTROL_CONTAINER_TEMPLATES.carouselViewAllPortrait;

    // The userId is needed to get the Jump recommendations
    const contextDataUpdated = { ...contextData, userId };

    return {
      hasData: async () => {
        return !!totalNumber;
      },
      isPaginationAllowed: async () => {
        return true;
      },
      getTotalNumber: async () => {
        return totalNumber;
      },
      getData: async (page, itemsPerPage = appConfig.ovpPageSize) => {
        // [AMAGI]
        // If template is amagi, then use the specific fetch
        // to get metadata from Amagi's API
        let getItems = getItemsByQuery;

        if (template.includes('amagi-live')) {
          getItems = getLiveAmagiItemsByQuery;
        } else if (template.includes('amagi')) {
          getItems = getItemsByQueryAmagi;
        }

        const { items, total } = await getItems({
          query,
          template,
          contextData: contextDataUpdated,
          pageNumber: page,
          itemsPerPage,
          userId
        });
        totalNumber = total;

        // put 'view all' button as the first element of the carousel
        if (hasViewAll && page === 1) {
          const itemToPush = {
            viewAll: true,
            query,
            displayText: config.displayText,
            id: `${template}-${Math.random()}`
          };
          items.unshift(itemToPush);
        }

        if (!items?.length && template !== 'elevate-channels' && page <= 1) {
          removeEmptyContainers();
        }

        return {
          data: items,
          total: totalNumber
        };
      }
    };
  }, [
    template,
    userId,
    contextData,
    appConfig.ovpPageSize,
    query,
    config.displayText,
    removeEmptyContainers
  ]);

  // If query, use dataSource
  // over for defaultItems
  const [ds, items] = useMemo(() => {
    if (query) {
      return [dataSource, null];
    }
    if (defaultItems?.length) {
      return [null, defaultItems];
    }

    return [null, null];
  }, [defaultItems?.length, dataSource, query]);

  // TODO: Consider changin template
  // for swimlane that uses query
  // if (template === 'elevate-channels') {
  //   console.log('[DEBUG][Shelf] Channels:', {
  //     contextData,
  //     defaultItems,
  //     query
  //   });
  // }

  return (
    <ShelfComponent
      template={template}
      ds={ds}
      items={items}
      itemType={itemType}
      gridAlignment={aligment}
      config={config}
      onClick={getItemOnClickFn({
        historyPush,
        authContext
      })}
      nav={nav}
      query={config.query}
    />
  );
};

Shelf.propTypes = {
  config: PropTypes.object,
  contextData: PropTypes.object,
  nav: PropTypes.object,
  items: PropTypes.array,
  query: PropTypes.string,
  template: PropTypes.string,
  removeEmptyContainers: PropTypes.func
};

export default Shelf;
