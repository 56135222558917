/**
 * @module models/CMS
 * @description The Dictionary Model to be used to allow i18n in the application
 */

/**
 * @typedef DictionaryEntry
 * @property {Strign} key key of the entry
 * @property {Strign} value Translated value of the entry
 */
/**
 * @typedef DictionaryModel
 * @property {String} name Name of the dictionary
 * @property {String<'lrt'|'rtl'>} dir directionallity between rtl and ltr
 * @property {String} code ISO locale code
 * @property {DictionaryEntry[]} entries directionallity between rtl and ltr
 */

/**
 *
 * Creates a Dictionary model, with name, dir, code and entries
 * @param {String} key The Dictionary key, which will be the code
 * @param {any} rawDictionary the non-parsed dictionary
 *
 * @return {DictionaryModel} a Dictionary Model
 */
const dictionary = (key, rawDictionary) => {
  const { name, directionality = 'ltr', ...entries } = rawDictionary;
  const getName = () => {
    return name;
  };
  const getDir = () => {
    return directionality;
  };
  const getISOCode = () => {
    return key;
  };
  const getEntries = () => {
    return entries;
  };

  return {
    name: getName(),
    dir: getDir(),
    code: getISOCode(),
    entries: getEntries()
  };
};

export default dictionary;
