import React from 'react';

import { Direction } from './addNavToItems';
import Layout from './Layout';

const HorizontalLayout = props => (
  <Layout {...props} direction={Direction.Horizontal} />
);

export default HorizontalLayout;
