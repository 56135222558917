import { useContext, useCallback, useEffect } from 'react';
import { ConfigContext, ConfigSetcontext } from '#/context/ConfigContext';

const useMenu = ({ menuVisible = true } = {}) => {
  const configContext = useContext(ConfigContext);
  const setConfigContext = useContext(ConfigSetcontext);

  if (setConfigContext === undefined || configContext === undefined) {
    throw new Error('useMenuConfig must be used within a ConfigProvider');
  }

  useEffect(() => {
    setConfigContext({ menuVisible });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const showMenu = useCallback(() => {
    setConfigContext({ menuVisible: true });
  }, [setConfigContext]);

  const hideMenu = useCallback(() => {
    setConfigContext({ menuVisible: false });
  }, [setConfigContext]);

  const isMenuVisible = useCallback(() => {
    return configContext.menuVisible;
  }, [configContext]);

  return { showMenu, hideMenu, isMenuVisible };
};
export default useMenu;
