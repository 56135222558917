// Translations on the client may fail. Moving translations to middleware
// import { translate } from '@vitalets/google-translate-api';
import { get, set } from '#/providers/settings';
import config from '#/config/app';

const TRANSLATIONS = 'translations';
const {
  amagi: { middlewareUrl }
} = config;

const translationUrl = `${middlewareUrl}/translate`;

// type RawResponse = Awaited<ReturnType<typeof translate>>['raw'];
type RequestPromise = Promise<{
  text: string;
}>;

let translationCache: Map<string, RequestPromise | string>;

const translate = async (
  input: string,
  { from, to }: { from: string; to: string }
) => {
  const translatePromise = await fetch(translationUrl, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ from, to, input })
  });

  if (!translatePromise.ok) {
    const { error } = await translatePromise.json();
    throw new Error(error);
  }

  return translatePromise.json() as RequestPromise;
};

export const initializeTranslate = async () => {
  if (translationCache) {
    return;
  }

  translationCache = new Map<string, RequestPromise | string>(
    await get<[string, string][]>('translations')
  );
};

const isStringStringArray = (
  pair: [string, string | RequestPromise]
): pair is [string, string] => {
  const val = pair[1];
  return typeof val === 'string';
};

const save = async (key: string, request: RequestPromise) => {
  translationCache.set(key, request);
  const { text } = await request;
  translationCache.set(key, text);
  const translationsArr: [string, string][] = Array.from(
    translationCache.entries()
  ).filter<[string, string]>(isStringStringArray);
  set(TRANSLATIONS, translationsArr);
  return text;
};

export const getTextTranslation = async (input: string): Promise<string> => {
  if (translationCache.has(input)) {
    const translation = translationCache.get(input);
    if (translation instanceof Promise) {
      return translation?.then(({ text }) => text) || '';
    }
    if (typeof translation === 'string') {
      return translation;
    }
  }

  try {
    const translatePromise = translate(input, {
      from: 'en',
      to: 'fr'
    });
    const text = await save(input, translatePromise);
    return text;
  } catch (error) {
    console.error(
      '[Translate] Error translating, returning unaltered string:',
      input,
      error
    );
    // invalidate cache
    translationCache.delete(input);
    return input;
  }
};
