export const SettingsEnum = {
  locale: 'locale',
  translations: 'translations'
} as const;

export type Settingskeys = keyof typeof SettingsEnum;

export interface Setting {}

export interface LocaleSetting extends Setting {
  code: string;
  dir: string;
}

export interface Settings {
  [SettingsEnum.locale]: LocaleSetting;
  [SettingsEnum.translations]: Array<string[]>;
}
