import { QUALITIES } from '#/config/constants';
import { current, environments } from '#/config/env';

const ACCEDO_ONE_URL = 'https://cdn.one.accedo.tv/files';
const CLOUDINARY_ACCEDO_ONE_URL = (height: number, quality: number) =>
  `https://res.cloudinary.com/accedotv/image/upload/h_${height}/q_${quality}/accedo-one-control`;
const replaceImgUrl = (
  imgUrl: string,
  currentPartial: string,
  newPartial: string
) => {
  return imgUrl?.replace(currentPartial, newPartial);
};

/**
 * Quality in local environment
 */
const DEV_QUALITY = QUALITIES.DEV;
const PROD_QUALITY = QUALITIES.PROD;

/**
 * Maximum Viewport Size of the application to ensure no image is returned with a bigger Width
 */
const MAX_VIEWPORT_SIZE = 1920;

export const getCloudinaryImage = ({
  imgUrl,
  imgWidth,
  width,
  height,
  crop = false
}: {
  imgUrl: string;
  imgWidth?: number;
  width?: number;
  height?: number;
  crop?: boolean;
}) => {
  const imageWidth = Math.min(MAX_VIEWPORT_SIZE, imgWidth || width || 5000);
  // Doing the image's template URL transform
  const resizeTemplateUrl = imgUrl?.replace(
    '{transforms}',
    `w_${imageWidth}${height ? `,h_${height}` : ''},q_${
      current === environments.development ? DEV_QUALITY : PROD_QUALITY
    },fl_progressive${crop ? ',c_crop' : ''}`
  );

  return resizeTemplateUrl;
};

const getCloudinaryItem = (item: { image: any }, height: any) => {
  const { image } = item;
  if (!image) {
    return item;
  }
  const cloudinaryImages = image.map((img: { fileUrl: any }) => {
    const { fileUrl } = img;
    const cloudinaryUrl = replaceImgUrl(
      fileUrl,
      ACCEDO_ONE_URL,
      CLOUDINARY_ACCEDO_ONE_URL(height, 40)
    );
    return {
      ...img,
      fileUrl: cloudinaryUrl
    };
  });

  return {
    ...item,
    image: cloudinaryImages
  };
};

export const getCloudinaryItems = (items: any[], height: number) => {
  return items.map(item => getCloudinaryItem(item, height));
};
