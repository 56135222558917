import { useCallback, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { HistoryContext, HistorySetContext } from '#/context/AppHistoryContext';

const useHistoryPush = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const historyContext = useContext(HistoryContext);
  const setContext = useContext(HistorySetContext);
  if (historyContext === undefined || setContext === undefined) {
    throw new Error('useHistoryPush must be used within a HistoryProvider');
  }
  return useCallback(
    ({ path, state, replace = false, skipCurrentOnBack = false }) => {
      const { stack } = historyContext;
      if (!replace) {
        // put the current path in the stack before navigate
        // the skipCurrentOnBack must be true when you do not want to go back to the route
        // from where the push is done
        stack.push({
          path: pathname,
          skipCurrentOnBack
        });
      }
      setContext({
        ...historyContext,
        stack
      });
      navigate(path, { replace, state });
    },
    [navigate, setContext, historyContext, pathname]
  );
};

export default useHistoryPush;
