export const isXMLTVDate = (xmlTvDate: string): boolean => {
  const isXmlTvDate =
    !xmlTvDate.includes('-') &&
    !xmlTvDate.includes('.') &&
    !xmlTvDate.includes(':') &&
    xmlTvDate.length === 20;

  return isXmlTvDate;
};

export const parseXMLTVDateToDate = (xmlTvDate: string): Date | void => {
  try {
    if (!xmlTvDate) {
      throw new Error('dateSource property is required!');
    }

    if (!isXMLTVDate(xmlTvDate)) {
      return new Date(xmlTvDate);
    }

    const str = xmlTvDate;
    const year = Number(str.substring(0, 4));
    const month = Number(str.substring(4, 6));
    const day = Number(str.substring(6, 8));
    const hour = Number(str.substring(8, 10));
    const minute = Number(str.substring(10, 12));
    const second = Number(str.substring(12, 14));

    const date = new Date(Date.UTC(year, month - 1, day, hour, minute, second));

    return date;
  } catch (e) {
    console.error(`LiveProgram error. ${e}`);
  }
};
