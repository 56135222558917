import React, { useState, useCallback, useMemo, createContext } from 'react';
import PropTypes from 'prop-types';

export const APIErrorContext = createContext({
  error: null,
  addError: () => {},
  removeError: () => {}
});

const APIErrorProvider = ({ children }) => {
  const [error, setError] = useState(false);

  const removeError = useCallback(() => setError(false), []);

  const addError = useCallback(() => setError(true), []);

  const contextValue = useMemo(
    () => ({
      error,
      addError,
      removeError
    }),
    [error, addError, removeError]
  );

  return (
    <APIErrorContext.Provider value={contextValue}>
      {children}
    </APIErrorContext.Provider>
  );
};

APIErrorProvider.propTypes = {
  children: PropTypes.node
};

export default APIErrorProvider;
