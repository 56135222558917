/**
 * @module services
 */

import CacheRequests from './helpers/Cache';

const invalidateCache = () => {
  const cache = new CacheRequests();
  cache.invalidate();
};

export { invalidateCache };
