import React, { useEffect, useState, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

import ContentGrid from '#/components/ContentGrid/ContentGrid';
import ChannelBannerItem from '#/components/Item/ChannelBannerlItem/ChannelBannerItem';

import { AuthContext } from '#/context/AuthContext';

import { getChannelData } from '#/services/ovp/live';

import { actionClickItem } from '#/services/analytics';
import useI18n from '#/hooks/useI18n';

import channelBannerTheme from './channelBanner.scss';

const itemWidth = 1120;
const itemHeight = 364;
const dotsSpacing = 60;

const ChannelBannerShelf = ({ onClick, items, config = {}, nav = {}, ds }) => {
  const [data, setData] = useState([]);
  const { currentLocale = {} } = useI18n();
  const { dir } = currentLocale;
  const authContext = useContext(AuthContext);
  const { userId } = authContext?.getUser?.() || {};
  useEffect(() => {
    if (ds) {
      // Don't fetch from API if data source is provided
      return;
    }

    const setChannelData = async () => {
      const channelsData = await getChannelData(userId, true);
      setData(channelsData?.entries || []);
    };
    setChannelData();
  }, []);

  const ChannelItem = useMemo(() => {
    if (ds) {
      // eslint-disable-next-line react/function-component-definition
      return props => <ChannelBannerItem {...props} />;
    }

    // eslint-disable-next-line react/function-component-definition
    return props => <ChannelBannerItem {...props} useAmagiOvp />;
  }, [ds]);

  if (!data.length && !ds) {
    return null; // TODO: add loader?
  }

  return (
    <ContentGrid
      data={ds ? null : data}
      ds={ds || null}
      dir={dir}
      keyProperty={ds ? 'id' : 'channelId'}
      itemWidth={itemWidth}
      itemHeight={itemHeight}
      horizontal
      headPadding={0}
      tailPadding={0}
      spacing={0}
      height={itemHeight + dotsSpacing}
      width={1920}
      loop
      theme={channelBannerTheme}
      onClick={source => {
        actionClickItem(source.data, config.displayText);
        onClick(source.data);
      }}
      DisplayComponent={ChannelItem}
      displayComponentProps={{ height: itemHeight }}
      nav={{
        ...nav,
        id: config.id || nav.id
      }}
      initialState={{
        id: items?.[0]?.id,
        position: 0
      }}
      showDots
      dotsClassName="channelRailDots"
    />
  );
};

ChannelBannerShelf.propTypes = {
  onClick: PropTypes.func,
  items: PropTypes.array,
  ds: PropTypes.object,
  config: PropTypes.object,
  nav: PropTypes.object
};

export default ChannelBannerShelf;
