/**
 * @module models/analytics
 */

/**
 * @typedef {Object} AnalyticsAsset
 * @property {String} contentId Item ID
 * @property {String} title Item Tile
 * @property {String} description Item Description
 * @property {String} publishDate Publication date
 * @property {String} seasonNumber Season number
 * @property {String} episodeNumber Episode number
 * @property {String} genre Genres/Categories
 * @property {String} duration duration of the content
 */

/**
 * AnalyticsAsset Model
 * @param {Object} Item Item: Episode/Movie
 * @returns {AnalyticsAsset} AnalyticsAsset
 */
const AnalyticsAsset = Item => {
  const {
    id: contentId,
    title,
    description,
    publishedDate: publishDate,
    seasonNumber,
    episodeNumber,
    duration,
    categories: genres
  } = Item;

  /**
   * Get the Item's id
   * @returns {String} ID
   */
  const getContentId = () => {
    return contentId;
  };

  /**
   * Get the Item's title
   * @returns {String} Title of the content
   */
  const getTitle = () => {
    return title;
  };

  /**
   * Get the Item's description
   * @returns {String} Description of the content
   */
  const getDescription = () => {
    return description;
  };

  /**
   * Get the Item's genre
   * @returns {String} Genres/categories
   */
  const getGenres = () => {
    return genres;
  };

  /**
   * Retrieves a pretty version of year, month and day rom a timestamp. (YYYY-MM-DD)
   * @param {Number} unixTimestamp The unix timestamp to get the value from
   * @returns {String} The year, month and day in the format YYYY-MM-DD
   */
  const getYYMMDD = unixTimestamp => {
    const date = new Date(unixTimestamp);
    const year = date.getFullYear();
    const month = `0${date.getMonth() + 1}`;
    const day = `0${date.getDate()}`;

    return `${year}-${month.slice(-2)}-${day.slice(-2)}`;
  };

  /**
   * Get the publication date
   * @returns {String} Publication date
   */
  const getPublishDate = () => {
    let formattedDate = publishDate;
    if (publishDate) {
      formattedDate = getYYMMDD(publishDate);
    }
    return formattedDate;
  };

  /**
   * Get the Item's duration
   * @returns {String} Duration
   */
  const getDuration = () => {
    return duration;
  };

  /**
   * Get the Item's seasonNumber if any
   * @returns {String} Duration
   */
  const getSeasonNumber = () => {
    return seasonNumber;
  };

  /**
   * Get the Item's episodeNumber if any
   * @returns {String} Duration
   */
  const getEpisodeNumber = () => {
    return episodeNumber;
  };

  return {
    contentId: getContentId(),
    title: getTitle(),
    description: getDescription(),
    publishDate: getPublishDate(),
    seasonNumber: getSeasonNumber(),
    episodeNumber: getEpisodeNumber(),
    duration: getDuration(),
    genre: getGenres()
  };
};

export default AnalyticsAsset;
