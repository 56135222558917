// These are the GIDs for loading a specific profile in this instance
// of control. This is based on the NAB POC but with different profiles.
// The profiles for the NAB POC uses the userId's as GIDs to laod the profile.
// Here we can remap those userId's to other values to match different profiles
// for the Bell POC.
// The 'amagi' string used to be used as the default gid for the NAB POC
// Now the defaultBellUserProfile should be the default gid for the Bell POC.
const defaultBellUserProfile = 'f7ddbb15fe1f4b15bd0d1355bc5847d1';

const gidMap = {
  // Id from user A
  c6c55f06c4fcdb9422d98f2b2a3132094f7fe729bc6864561e07d2c7f5d40f40: defaultBellUserProfile,
  // Id from user B
  '15a439c99fa52a79dd747b34e3569edd21e0417fa5b547be43cb74302b7de9ba': defaultBellUserProfile,
  '123': '123', // For tests
  '1234': '1234', // For tests
  amagi: defaultBellUserProfile
};

export const getGID = id => {
  return gidMap[id] || defaultBellUserProfile;
};
