/**
 * @module services/routes
 * @description
 * Service to handle the fetch of Routes to do the mapping
 */
import cms from '#/providers/cms';
import routeModel from '#/models/route';
import { PROVIDER_TYPE } from '#/config/constants';

import fetcher from '../../helpers/fetcher';

const getRoutesMapping = async () => {
  const routesMapping = [];
  const routesMappingResponse = await fetcher({
    cacheId: `${PROVIDER_TYPE.cms}-routeMapping`,
    fetchFn: () => cms.getRoutesMapping()
  });
  routesMappingResponse?.forEach(route => {
    routesMapping.push(routeModel(route));
  });

  return routesMapping;
};

export { getRoutesMapping };
