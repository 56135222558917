/**
 * @typedef assetTypes
 * @readonly
 * @enum {String}
 */
const assetTypes = {
  EPISODE: 'episode',
  TVSHOW: 'tvshow',
  MOVIE: 'movie',
  LIVE_CHANNEL: 'live',
  ELEVATE_TVSHOW: 'tvshow'
};

const assetTypePageMap = {
  [assetTypes.EPISODE]: '/episode-details/:id',
  [assetTypes.TVSHOW]: '/series-details/:id',
  [assetTypes.MOVIE]: '/movie-details/:id',
  [assetTypes.LIVE_CHANNEL]: '/live/:id',
  [assetTypes.ELEVATE_TVSHOW]: '/episode-details/:id-s01e01'
};

export { assetTypes, assetTypePageMap };
