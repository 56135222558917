import env from './env';
import app from './app';

/*
 * Exporting the aggregated configurations.
 */
export default {
  app,
  env
};
