import React, {
  useEffect,
  useState,
  useCallback,
  useContext,
  useMemo
} from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { formatProgramTime } from '@accedo/vdkweb-epg';
import { withFocus } from '@accedo/vdkweb-navigation';
import { RoundButton } from '#/components/RoundButton/RoundButton';
import ImageWithPreload from '#/components/ImageWithPreload/ImageWithPreload';
import { AuthContext } from '#/context/AuthContext';
import useItemStyle from '#/hooks/useItemStyle';
import { getProgramData, getProgramDataAmagi } from '#/services/ovp/live';
import amagiLiveOvpV2 from '#/providers/ovp/amagi/amagi_live_v2';
import { getTranslation } from '#/services/i18n';

import channelBannerItemTheme from './channelBannerImageItem.scss';

const ChannelBannerImageItem = ({
  onClick,
  theme = channelBannerItemTheme,
  nav = {},
  data,
  containerStyle,
  useAmagiOvp
}) => {
  const [program, setProgramData] = useState({});
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    setProgramData(data);
    setProgress(0);
  }, []);

  // [AMAGI][ALWAYS FREE TO WATCH]
  // Removed for the Amagi DEMO. Temporally we should turn it free to watch.
  //
  // const authContext = useContext(AuthContext);
  // const actionButtonText = useMemo(
  //   () =>
  //     authContext.isAuthenticated
  //       ? getTranslation('watchNowButton')
  //       : getTranslation('login'),
  //   [authContext]
  // );
  const backgroundClassName = `${theme.background} ${
    nav.isFocused ? theme.backgroundFocused : ''
  }`;
  // const updateProgress = useCallback(() => {
  //   if (program?.endTime && program?.startTime) {
  //     const currentTime = Date.now();
  //     const diff = program.endTime - program.startTime;
  //     const diffToNow = currentTime - program.startTime;
  //     setProgress((diffToNow * 100) / diff);
  //   }
  // }, [program?.startTime, program?.endTime]);

  // useEffect(() => {
  //   const updateData = async () => {
  //     let programData = [];
  //     if (data.channelId) {
  //       programData = await amagiLiveOvpV2.getTvListings(data.queryParams);
  //     } else {
  //       programData = await amagiLiveOvpV2.getLiveEventsListing(
  //         data.queryParams
  //       );
  //     }
  //     const currentProgram = programData.find(p => {
  //       if (data.channelId) {
  //         return data.channelId === p.channelId;
  //       }

  //       return data.id === p.id;
  //     });

  //     setProgramData(currentProgram);
  //     updateProgress();
  //   };
  //   updateData();

  //   const interval = setInterval(() => {
  //     updateData();
  //   }, 2 * 60 * 1000); // TODO: update with value from Control or default

  //   return () => {
  //     return clearInterval(interval);
  //   };
  // }, [data, updateProgress]);

  // if (!program?.railPoster) {
  //   console.warn('[DEBUG] No poster?', program);
  // }

  return (
    <div style={{ ...containerStyle }}>
      <div
        className={backgroundClassName}
        onMouseOver={nav.onMouseOver}
        onMouseOut={nav.onMouseOut}
      >
        <ImageWithPreload
          src={program?.railPoster}
          width="1120px"
          height="100%"
        />
        <div className={theme.contentContainer}>
          <div className={theme.content}>
            {/* <div className={theme.channelContainer}>
              <span className={theme.channelLogo}>
                {data.channelId && (
                  <img
                    src={useAmagiOvp ? data.logo?.fileUrl : data.logo}
                    alt="Channel Logo"
                  />
                )}
              </span>
              <span className={theme.channelTitle}>
                {data.displayText || data.title}
              </span>
            </div> */}
            {/* <div className={theme.programContainer}>
              <div className={theme.programTitle}>
                {program?.programTitle || program?.title}
              </div>
              <div className={theme.programDetail}>
                {formatProgramTime(
                  dayjs(program?.startTime),
                  dayjs(program?.endTime),
                  'HH:mm'
                )}
              </div>
            </div> */}
            {
              // [AMAGI][ALWAYS FREE TO WATCH]
              // Removed for the Amagi DEMO. Temporally we should turn it free to watch.
              //
            }
            {/* <RoundButton
              className={theme.button}
              onClick={onClick}
              displayText={actionButtonText}
              nav={nav}
            /> */}
            <div className={theme.contentPadding} />
            <RoundButton
              className={theme.button}
              onClick={onClick}
              displayText={getTranslation('watchNowButton')}
              nav={nav}
            />
            {data?.progress && (
              <div className={theme.progress}>
                <span style={{ width: `${progress}%` }} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

ChannelBannerImageItem.propTypes = {
  data: PropTypes.object,
  containerStyle: PropTypes.any,
  nav: PropTypes.object,
  onClick: PropTypes.func,
  theme: PropTypes.object,
  useAmagiOvp: PropTypes.bool
};

export default withFocus(ChannelBannerImageItem);
