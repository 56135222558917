/**
 * @module services/movie
 * @description
 * Service to handle the fetch of movies
 */
import ovp from '#/providers/ovp';
import Movie from '#/models/movie/movie';
import { PROVIDER_TYPE } from '#/config/constants';
import { getMovieById } from '#/providers/ovp/amagi/amagi';
import fetcher from '#/services/helpers/fetcher';
import Item from '#/models/item/item';

const { amagi, ovp: accedo } = PROVIDER_TYPE;

const getMovie = async id => {
  const isAmagi = id.startsWith('amagi_');
  const provider = isAmagi ? amagi : accedo;
  const fetchFn = isAmagi ? getMovieById : ovp.getMovieById;
  const rawMovie = await fetcher({
    cacheId: `${provider}-movie-${id}`,
    fetchFn: () => fetchFn(id)
  });
  return isAmagi ? Item(rawMovie) : Movie(rawMovie);
};

export { getMovie };
