// This is a header for TV only.
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FocusDiv } from '@accedo/vdkweb-tv-ui';
import { focusManager } from '@accedo/vdkweb-navigation';

import useAppConfig from '#/hooks/useAppConfig';
import usePage from '#/hooks/usePage';
import useMenu from '#/hooks/useMenu';
import useI18n from '#/hooks/useI18n';
import Menu from '#/components/Menu/Menu';
import { navIdMap } from '#/utils/navigationHelper';

import styles from './tvHeader.scss';

const NAV_IDS = navIdMap.MENU.HEADER;

const nav = {
  container: {
    id: NAV_IDS.CONTAINER,
    forwardFocus: NAV_IDS.MENU,
    useLastFocus: true
  },
  menu: {
    id: NAV_IDS.MENU,
    parent: NAV_IDS.CONTAINER,
    forwardFocus: `${NAV_IDS.MENU_ITEM}0`,
    itemId: NAV_IDS.MENU_ITEM
  }
};

const TVHeader = ({ items }) => {
  const { getCurrentPageId } = usePage();
  const { config = {} } = useAppConfig();
  const { currentLocale = {} } = useI18n();
  const { isMenuVisible } = useMenu();
  const focusCurrentPage = () => {
    const currentPageId = getCurrentPageId();
    currentPageId && focusManager.changeFocus(getCurrentPageId());
  };
  const { dir } = currentLocale;

  const containerClass = classNames(styles.container, {
    [styles.isVisible]: isMenuVisible()
  });

  const imageClass = classNames(styles.img, styles[dir]);

  useEffect(() => {
    focusManager.changeFocus(nav.menu.id);
  }, []);

  if (!items && !config.menuId) {
    return null;
  }

  return (
    <header dir={dir}>
      <FocusDiv
        className={containerClass}
        nav={{
          ...nav.container,
          internal: {
            nextdown: focusCurrentPage
          }
        }}
        dir={dir}
      >
        <Menu
          entryId={config.menuId}
          items={items}
          nav={nav.menu}
          imgLogo={config.appLogo}
          logoClassName={imageClass}
        />
      </FocusDiv>
    </header>
  );
};

TVHeader.propTypes = {
  items: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

export default TVHeader;
