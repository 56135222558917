import fetch from '@accedo/vdkweb-fetch';

import config from '#/config';
import local from '../local/local';

const { accedoOvpUrl } = config.app;

/**
 * @module providers/idp
 * @description
 * Provider IDP implementation for Accedo
 */

/**
 * A map with all the services used by this template.
 */
const services = {
  loginByCredentials: `${accedoOvpUrl}/auth`,
  validateUser: token => `${accedoOvpUrl}/auth/${token}/valid`,
  logout: token => `${accedoOvpUrl}/auth/${token}`,
  // different urls required on elevate for the auth microservice
  loginByPairingCode: `auth/user/login`, // TODO: update with pairing service for remote cases
  pairingCode: `auth/user/pairing` // TODO: update with pairing service for remote cases
};

/**
 * The common options used by services. Except for logout
 * that uses DELETE.
 */
const requestOptions = {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json'
  }
};

const login = async (username, password) => {
  requestOptions.headers = {
    ...requestOptions.headers,
    'X-User': username,
    'X-Password': password
  };

  const options = {
    ...requestOptions
  };

  return fetch(services.loginByCredentials, options).then(async response => {
    // Fetch API only rejects a promise when a network error is encountered
    // so an error response needs to be thrown forward
    // https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch#Checking_that_the_fetch_was_successful
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    const rawUser = await response.json();
    const extendedRawUser = {
      ...rawUser,
      username
    };
    await local._saveUser(extendedRawUser);
    return extendedRawUser;
  });
};

const validateUser = async user => {
  const { token, userId } = user;
  const options = {
    ...requestOptions,
    headers: {
      ...requestOptions.headers,
      'X-UserId': userId
    },
    method: 'GET'
  };

  return fetch(services.validateUser(token), options).then(response => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }

    return response.json().then(async () => user);
  });
};

const logout = async token => {
  const options = {
    ...requestOptions,
    method: 'DELETE'
  };
  await local._clearUser(); // Clear User from localStorage
  return fetch(services.logout(token), options).then(response => response.ok);
};

const getPairingCode = deviceId => {
  // const options = {
  //   ...requestOptions,
  //   body: JSON.stringify({ deviceId })
  // };

  if (deviceId === 'test-device-id') {
    return Promise.resolve({ code: 'ABCD' });
  }

  return Promise.resolve({
    code: Array(6)
      .fill(0)
      .map(_ => Math.trunc(Math.random() * 10))
      .join('')
      .substring(0, 6)
  });

  // return fetch(services.pairingCode, options).then(response => response.json());
};

if (typeof window !== 'undefined') {
  window._getPairingCode = getPairingCode;
}

const pair = async (deviceId, abortController = null) => {
  const options = {
    ...requestOptions,
    signal: abortController ? abortController.signal : null,
    body: JSON.stringify({ deviceId })
  };

  return fetch(services.loginByPairingCode, options).then(response => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json();
  });
};

export default { login, validateUser, logout, getPairingCode, pair };
