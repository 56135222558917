import React from 'react';
import PropTypes from 'prop-types';

import splashSpinner from '#/static/images/tv/splash_spinner.png';
import splashLogo from '#/static/images/tv/splash_logo.png';

import styles from './splashScreen.scss';

const STATUS = {
  NO_STATUS: 'no_status',
  NO_CONFIG: 'no_config',
  MAINTENANCE: 'maintenance', // TODO: implement this case
  DATA_LOADING: 'data_loading'
};

const SplashScreen = ({ status, src }) => {
  const showSpinner = status === STATUS.DATA_LOADING;
  const defaultImage = showSpinner ? splashSpinner : splashLogo;
  const image = src || defaultImage;
  const backgroundStyle = styles.root;

  return (
    <div className={backgroundStyle}>
      {showSpinner && (
        <div className={styles.splashContainer}>
          <img
            alt="Splash"
            className={showSpinner ? styles.spinner : ''}
            src={image}
          />
        </div>
      )}
    </div>
  );
};

SplashScreen.propTypes = {
  status: PropTypes.oneOf([
    STATUS.NO_STATUS,
    STATUS.NO_CONFIG,
    STATUS.MAINTENANCE,
    STATUS.DATA_LOADING
  ]),
  src: PropTypes.string
};

SplashScreen.status = STATUS;

export default SplashScreen;
