import OnNowTSN1 from '#/static/images/tsn-channels/on-now-tsn-1.png';
import OnNowTSN2 from '#/static/images/tsn-channels/on-now-tsn-2.png';
import OnNowTSN3 from '#/static/images/tsn-channels/on-now-tsn-3.png';
import OnNowTSN4 from '#/static/images/tsn-channels/on-now-tsn-4.png';
import OnNowTSN5 from '#/static/images/tsn-channels/on-now-tsn-5.png';

const ONE_HOUR = 1 * 60 * 60 * 1000;

const createTsnAssets = () => [
  {
    channelId: '766',
    id: '667598d123eec60017543cfb-1-1719000592000',
    description: '',
    endTime: Date.now() + ONE_HOUR,
    startTime: Date.now() - ONE_HOUR,
    title: 'Euro 2024 Finals',
    videoUrl:
      'https://cdn-apse1-prod.tsv2.amagi.tv/linear/partner-infast/playlist.m3u8',
    railPoster: OnNowTSN1,
    type: 'live',
    images: [],
    poster: ''
  },
  {
    channelId: '766',
    id: '667598d123eec60017543cfb-2-1719000592000',
    description: '',
    endTime: Date.now() + ONE_HOUR,
    startTime: Date.now() - ONE_HOUR,
    title: 'NHL: Edmonton Oilers vs Florida Panthers',
    videoUrl:
      'https://cdn-apse1-prod.tsv2.amagi.tv/linear/partner-infast/playlist.m3u8',
    railPoster: OnNowTSN2,
    type: 'live',
    images: [],
    poster: ''
  },
  {
    channelId: '766',
    id: '667598d123eec60017543cfb-3-1719000592000',
    description: '',
    endTime: Date.now() + ONE_HOUR,
    startTime: Date.now() - ONE_HOUR,
    title: 'Spanish Grand Prox 2024',
    videoUrl:
      'https://cdn-apse1-prod.tsv2.amagi.tv/linear/partner-infast/playlist.m3u8',
    railPoster: OnNowTSN3,
    type: 'live',
    images: [],
    poster: ''
  },
  {
    channelId: '766',
    id: '667598d123eec60017543cfb-4-1719000592000',
    description: '',
    endTime: Date.now() + ONE_HOUR,
    startTime: Date.now() - ONE_HOUR,
    title: 'Sportscenter',
    videoUrl:
      'https://cdn-apse1-prod.tsv2.amagi.tv/linear/partner-infast/playlist.m3u8',
    railPoster: OnNowTSN4,
    type: 'live',
    images: [],
    poster: ''
  },
  {
    channelId: '766',
    id: '667598d123eec60017543cfb-5-1719000592000',
    description: '',
    endTime: Date.now() + ONE_HOUR,
    startTime: Date.now() - ONE_HOUR,
    title: 'CFL: Edmonton Elks vs Toronto Argonauts',
    videoUrl:
      'https://cdn-apse1-prod.tsv2.amagi.tv/linear/partner-infast/playlist.m3u8',
    railPoster: OnNowTSN5,
    type: 'live',
    images: [],
    poster: ''
  }
];

export const getBannerImages = type => {
  switch (type) {
    case 'tsn':
      return createTsnAssets();
    default:
      return [];
  }
};
