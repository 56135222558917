/**
 * @module models/CMS
 */

/**
 * This model represents the Page.
 *
 * Route representation
 * @typedef {Object} Route
 * @property {String} route route string
 * @property {String} template route template from the list defined in config/constant (PAGE_TEMPLATES)
 * @property {String} id CMD page ID
 */

/**
 * Route Model
 * @param {Object} rawRoute Route
 * @returns {Route} route - The {@link Route} representation
 */
const routeModel = rawRoute => {
  const {
    route,
    page: { template, id }
  } = rawRoute;

  const getRoute = () => {
    return route.indexOf('/') === 0 ? route : `/${route}`;
  };

  const getTemplate = () => {
    return template;
  };

  const getPageId = () => {
    return id;
  };

  return {
    id: getPageId(),
    template: getTemplate(),
    route: getRoute()
  };
};

export default routeModel;
