import ovp from '#/providers/ovp';
import Movie from '#/models/movie/movie';
import Show from '#/models/show/show';
import { PROVIDER_TYPE } from '#/config/constants';
import fetcher from '../../helpers/fetcher';

/**
 * @module services/search
 * @description
 * Service to handle the search methods
 */

/**
 * Sort two elements to determine the best order
 * @param {Object} a Array element
 * @param {Object} b Array element
 * @returns {Number} Sort result
 */
const sort = (a, b) => {
  if (a.title < b.title) {
    return -1;
  }
  if (a.title > b.title) {
    return 1;
  }
  return 0;
};

/**
 * Search for Movies and Shows
 * @param {String} keyword Keyword to search
 * @param {Number} [amount] Items per type
 * @returns {Array<Object>} Results
 */
const search = async (keyword, amount = 5) => {
  const rawMovies = await fetcher({
    cacheId: `${PROVIDER_TYPE.ovp}-search-movies-${keyword}`,
    fetchFn: () => ovp.searchMovies(keyword, amount)
  });

  const rawShows = await fetcher({
    cacheId: `${PROVIDER_TYPE.ovp}-search-shows-${keyword}`,
    fetchFn: () => ovp.searchShows(keyword, amount)
  });

  const movies = rawMovies.entries.map(movie => Movie(movie));
  const shows = rawShows.entries.map(show => Show(show));

  return {
    count: movies.length + shows.length,
    movies,
    shows
  };
};

/**
 * Get last publised movies and shows to represent as trends
 * @param {Number} amount Items per type
 * @returns {Array<Object>} Results
 */
const getTrends = async (amount = 5) => {
  const options = { pageSize: amount, sortBy: '-publishedDate' };
  const rawMovies = await fetcher({
    cacheId: `${PROVIDER_TYPE.ovp}-search-movies`,
    fetchFn: () => ovp.getMovies(options)
  });

  const rawShows = await fetcher({
    cacheId: `${PROVIDER_TYPE.ovp}-search-shows`,
    fetchFn: () => ovp.getTvShows(options)
  });

  const movies = rawMovies.entries.map(movie => Movie(movie));
  const shows = rawShows.entries.map(show => Show(show));

  const results = movies.concat(shows);
  return results.sort(sort);
};

export { search, getTrends };
