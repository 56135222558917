import React, { useState, createContext } from 'react';

interface AssetImageContextValue {
  extraDim?: boolean;
}

const defaultValue: AssetImageContextValue = {};

export const AssetImageContext = createContext(defaultValue);
export const AssetImageSetContext = (createContext(() => {
  /* Empty */
}) as unknown) as React.Context<
  React.Dispatch<React.SetStateAction<AssetImageContextValue>>
>;

export const AssetImageProvider: React.FC = ({ children }) => {
  const [state, setState] = useState(defaultValue);
  return (
    <AssetImageContext.Provider value={state}>
      <AssetImageSetContext.Provider value={setState}>
        {children}
      </AssetImageSetContext.Provider>
    </AssetImageContext.Provider>
  );
};
