import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Shelf from '#/components/Shelf';
import ContainerComp from '#/components/Container/Container';

const Container = props => {
  const {
    contextData,
    items,
    query,
    template,
    nav,
    moreItems,
    index,
    id,
    title,
    displayText,
    gridConfig,
    removeEmptyContainers
  } = props;
  const hasItems = items && items.length > 0;

  const removeContainer = useCallback(() => {
    removeEmptyContainers(id);

    console.warn(`[debug] Empty container in the index ${index}`);
  }, [removeEmptyContainers, index, id]);

  if (
    !hasItems &&
    !query &&
    template !== 'elevate-channels' &&
    template !== 'elevate-channels-amagi-live'
  ) {
    return null;
  }

  const shelfProps = {
    contextData,
    config: {
      displayText,
      index,
      id,
      moreItems,
      query,
      title,
      template,
      prefix: `prefix-${id}`,
      ...gridConfig
    },
    nav,
    template,
    removeEmptyContainers: removeContainer
  };

  return (
    <ContainerComp {...props}>
      <Shelf {...shelfProps} items={items} query={query} />
    </ContainerComp>
  );
};

Container.propTypes = {
  contextData: PropTypes.object,
  gridConfig: PropTypes.shape({
    useInternalArrows: PropTypes.bool,
    gridRef: PropTypes.object,
    onChange: PropTypes.func
  }),
  id: PropTypes.string,
  index: PropTypes.number,
  items: PropTypes.array,
  moreItems: PropTypes.bool,
  nav: PropTypes.object,
  query: PropTypes.string,
  title: PropTypes.string,
  displayText: PropTypes.string,
  template: PropTypes.string,
  removeEmptyContainers: PropTypes.func
};

export default Container;
