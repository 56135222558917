import { ITEM_TYPES } from '#/config/constants';

export const getGridSettingMap = (itemType, enableSpotlight) => {
  const gridCols = {
    [ITEM_TYPES.Portrait]: 5,
    [ITEM_TYPES.GridLandscape]: 3,
    [ITEM_TYPES.GridPortrait]: 5,
    [ITEM_TYPES.Wide]: enableSpotlight ? 2 : 3,
    [ITEM_TYPES.Trend]: 1
  };

  const gridRows = {
    [ITEM_TYPES.Portrait]: 2,
    [ITEM_TYPES.GridLandscape]: 3,
    [ITEM_TYPES.GridPortrait]: 2,
    [ITEM_TYPES.Wide]: 2,
    [ITEM_TYPES.Trend]: 5
  };

  return {
    col: gridCols[itemType],
    row: gridRows[itemType]
  };
};
