import Cache from './Cache';

const USE_CACHE = true;

const fetcher = async ({ fetchFn, cacheId } = {}) => {
  const cache = new Cache();
  let fetchExecution;
  let error;

  if (cacheId && cache.has(cacheId)) {
    const requestFromCache = cache.get(cacheId);
    const contentFromCache = await requestFromCache;
    return contentFromCache;
  }
  try {
    fetchExecution = fetchFn();
  } catch (e) {
    console.warn(`[debug] Error getting data: ${e}`);
    error = e;
  }
  if (!error) {
    if (USE_CACHE || cacheId) {
      cache.set(cacheId, fetchExecution);
    }
    return fetchExecution;
  }
  throw new Error('No data available');
};

export default fetcher;
