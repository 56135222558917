import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { FocusDiv } from '@accedo/vdkweb-tv-ui';
import { focusManager } from '@accedo/vdkweb-navigation';

import { FocusRoundButton } from '#/components/RoundButton/RoundButton';
import { getItemsByQuery } from '#/services/ovp';
import { ArrowDownIcon } from '#/components/Icons/Icons';
import SelectorList from '#/components/SelectorList/SelectorList';
import usePage from '#/hooks/usePage';
import filterableShelfTheme from './filterableShelf.scss';

const Filter = ({
  item,
  items,
  nav,
  isSelectorVisible,
  onClick,
  onOptionSelected,
  type,
  query
}) => {
  const [filterItems, setFilterItems] = useState(items);
  const { setPageFilter } = usePage();

  const getFilterData = useCallback(async filterItemsQuery => {
    const data = await getItemsByQuery({ query: filterItemsQuery });
    setFilterItems(data);
  }, []);

  const [selectedItem, setSelectedItem] = useState();

  const onSelectFilter = selectedFilter => {
    setPageFilter({ selectedFilter, type }); // update context
    setSelectedItem(selectedFilter.id === 'clear' ? null : selectedFilter); // no selected item on clear
    focusManager.changeFocus(nav.buttonId);
    onOptionSelected();
  };

  useEffect(() => {
    if (!items && query) {
      getFilterData(query);
    }
  }, [getFilterData, items, query]);

  return (
    <div className={filterableShelfTheme.wrapper} key={type}>
      <FocusDiv
        className={filterableShelfTheme.buttonWrapper}
        nav={{
          forwardFocus: nav.buttonId,
          ...nav
        }}
      >
        <FocusRoundButton
          className={filterableShelfTheme.button}
          displayText={item.displayText}
          nav={{
            id: nav.buttonId,
            parent: nav.id
          }}
          onClick={() => onClick(type)}
          Icon={ArrowDownIcon}
          iconClassName={filterableShelfTheme.arrowIcon}
        />
      </FocusDiv>
      {isSelectorVisible && (
        <SelectorList
          nav={{
            id: nav.selectorListId
          }}
          items={filterItems}
          onClick={onSelectFilter}
          selectedItem={selectedItem}
          navId={nav.selectorListId}
          type={type}
        />
      )}
    </div>
  );
};

Filter.propTypes = {
  item: PropTypes.object,
  items: PropTypes.array,
  isSelectorVisible: PropTypes.bool,
  nav: PropTypes.object,
  onClick: PropTypes.func,
  onOptionSelected: PropTypes.func,
  query: PropTypes.string,
  type: PropTypes.string
};

export default Filter;
