/**
 * @module services/config
 * @description
 * Service to handle the fetch of config file from the CMS.
 * @return {VikiElevateConfig} The modelised config file.
 */
import cms from '#/providers/cms';
import configModel from '#/models/config';
import { PROVIDER_TYPE } from '#/config/constants';
import { getGID } from '#/providers/shared/control/mapIds';

import fetcher from '../../helpers/fetcher';

const getConfiguration = async userId => {
  const gid = getGID(userId);
  const rawConfig = await fetcher({
    cacheId: `${PROVIDER_TYPE.cms}${gid ? `-${gid}` : ''}-configuration`,
    fetchFn: () => cms.getConfiguration(gid)
  });
  const config = configModel(rawConfig);

  return config;
};

export { getConfiguration };
