import React, { useState, createContext } from 'react';

interface MenuContextValue {
  appLogo?: string;
  wrapperClass?: string;
  logoClass?: string;
}

const defaultValue: MenuContextValue = {};

export const MenuContext = createContext(defaultValue);
export const MenuSetContext = (createContext(() => {
  /* Empty */
}) as unknown) as React.Context<
  React.Dispatch<React.SetStateAction<MenuContextValue>>
>;

export const MenuProvider: React.FC = ({ children }) => {
  const [state, setState] = useState(defaultValue);
  return (
    <MenuContext.Provider value={state}>
      <MenuSetContext.Provider value={setState}>
        {children}
      </MenuSetContext.Provider>
    </MenuContext.Provider>
  );
};
