/**
 * @module models/dictionary/locale
 * @description The locale Model to be used to handle different locales to select in the application
 */

/**
 * @typedef LocaleModel
 * @property {String} displayName Name of the locale to be displayed
 * @property {String} code ISO locale code
 */

/**
 *
 * Creates a Locale model, with code and displayName
 * @param {any} rawLocale the non-parsed locale
 *
 * @return {LocaleModel} a Dictionary Model
 */
const locale = rawLocale => {
  const { code, displayName } = rawLocale;
  const getCode = () => {
    return code;
  };
  const getDisplayName = () => {
    return displayName;
  };

  return {
    code: getCode(),
    displayName: getDisplayName()
  };
};

export default locale;
