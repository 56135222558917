import React from 'react';
import PropTypes from 'prop-types';
import { withFocus } from '@accedo/vdkweb-navigation';

import categoryItemTheme from './epgCategoryItem.scss';

const EPGCategoryItem = ({
  containerStyle,
  data,
  nav,
  width,
  height,
  onClick
}) => {
  const selectedStyle =
    !nav.isFocused && data.isSelected ? categoryItemTheme.selected : '';

  const focusedStyle =
    nav.isFocused && !data.isSelected ? categoryItemTheme.focused : '';

  const selectedAndFocused =
    data.isSelected && nav.isFocused ? categoryItemTheme.focused : '';

  const wrapperClassName = `${categoryItemTheme.wrapper} ${focusedStyle ||
    selectedStyle ||
    selectedAndFocused}`;

  const wrapperStyle = {
    ...containerStyle,
    ...(width ? { width } : {}),
    ...(height ? { height } : {})
  };

  return (
    <div
      className={wrapperClassName}
      style={wrapperStyle}
      onMouseOver={nav.onMouseOver}
      onMouseOut={nav.onMouseOut}
      onClick={onClick}
      role="button"
    >
      <div className={categoryItemTheme.title}>{data.title}</div>
    </div>
  );
};

EPGCategoryItem.propTypes = {
  containerStyle: PropTypes.object,
  data: PropTypes.object,
  height: PropTypes.any,
  width: PropTypes.any,
  nav: PropTypes.object,
  onClick: PropTypes.func
};

export default withFocus(EPGCategoryItem);
