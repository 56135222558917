import ovp, { accedoOvp } from '#/providers/ovp';
import { assetTypes } from '#/models/assetTypes';
// import LiveModelBuilder from '#/models/live/liveProgram';
import { SimpleLiveModelBuilder } from '#/models/live/liveProgram';
import fetcher from '../../helpers/fetcher';

const CHANNEL_COUNT = 20;

/**
 * @module services/ovp
 * @description
 * Service to handle the fetch of ovp live data
 */

const getTvListings = async (
  userId,
  { startTime, endTime, count, offset = 0 }
) => {
  const cacheId = `tvlintings${userId ? `-${userId}` : ''}${
    startTime ? `-${startTime}` : ''
  }${endTime ? `-${endTime}` : ''}${count ? `-${count}` : ''}${`-${offset}`}`;
  const data = await fetcher({
    cacheId,
    fetchFn: () =>
      ovp.getTvListings(userId, { startTime, endTime, count, offset }, cacheId)
  });

  return data; // No modelized as modelized on the Provider
};

const getChannelData = async (userId, filterHighlight) => {
  const data = await fetcher({
    fetchFn: () => ovp.getChannelData(userId, filterHighlight)
  });
  return data;
};

/**
 * Retrieves a pretty version of hours and minutes information
 * from a timestamp. (HH:MM)
 * @param {Number} unixTimestamp The unix timestamp to get the value from
 * @returns {String} The hours and minutes in the format HH:SS
 */
const getTimeHoursMins = unixTimestamp => {
  const date = new Date(unixTimestamp);
  const hours = `0${date.getHours()}`;
  const minutes = `0${date.getMinutes()}`;

  return `${hours.slice(-2)}:${minutes.slice(-2)}`;
};

/**
 * Fetches information for live data between star time and end time.
 * @param {Number} startTime The start time to fetch information (unix timestamp in ms)
 * @param {Number} endTime The end time to fetch information (unix timestamp in ms)
 * @returns {Array<Object>} Live programs
 */
const getLiveData = async ({ userId, startTime, endTime }) => {
  const channels = await fetcher({
    fetchFn: () => ovp.getChannelData()
  });
  const count = channels.totalCount || CHANNEL_COUNT;
  const offset = 0;
  const data = await getTvListings(userId, {
    startTime,
    endTime,
    count,
    offset
  });

  return data.entries.map(record => {
    const { programs, images, ...channelRest } = record;
    const channel = { ...channelRest };
    const image = images && images.length > 0 ? images[0] : null;
    const curProgram = programs.length > 0 ? programs[0] : null;
    const nextProgram = programs.length > 1 ? programs[1] : null;

    const curStartTime = getTimeHoursMins(curProgram?.startTime);
    const curEndTime = getTimeHoursMins(curProgram?.endTime);
    const nextStartTime = getTimeHoursMins(nextProgram?.startTime);
    const nextEndTime = getTimeHoursMins(nextProgram?.endTime);

    return {
      id: record.id,
      type: assetTypes.LIVE_CHANNEL,
      channel,
      image,

      curProgram,
      nextProgram,

      curTitle: curProgram?.title || '',
      curStartTime,
      curEndTime,

      nextTitle: nextProgram?.title || '',
      nextStartTime,
      nextEndTime
    };
  });
};

const getProgramData = async ({ channel, startTime, segmentationValue }) => {
  const tvListingsData = await accedoOvp.getChannelTvListings({
    channel,
    startTime,
    segmentationValue
  });

  const liveProgram = new SimpleLiveModelBuilder(tvListingsData);

  return liveProgram.create();
};

const getProgramDataAmagi = async (channel, startTime) => {
  const tvListingsData = await ovp.getChannelTvListings({ channel, startTime });

  return tvListingsData;
};

export {
  getLiveData,
  getTvListings,
  getProgramData,
  getProgramDataAmagi,
  getChannelData
};
