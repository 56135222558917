import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'react-fast-compare';
import cx from 'classnames';
import { useLocation } from 'react-router-dom';

import getResolution from '#/utils/getResolution';
import toCamelCase from '#/utils/toCamelCase';
import { R720p } from '#/theme/variables/breakpoints';
import { ACCEDO_CONTROL_CONTAINER_TEMPLATES, ROUTES } from '#/config/constants';
import { getTranslation } from '#/services/i18n';
import useI18n from '#/hooks/useI18n';

import styles from './Container.scss';

const getTitlePrefix = route => {
  switch (route) {
    case ROUTES.tsn:
      return {
        prefix: getTranslation('includedWith').replace('{{BRAND}}', 'TSN'),
        class: styles.tsn
      };
    case ROUTES.crave:
      return {
        prefix: getTranslation('includedWith').replace('{{BRAND}}', 'Crave'),
        class: styles.crave
      };
    default:
      return null;
  }
};

const {
  hero,
  imageHero,
  continueWatching,
  carouselPortrait,
  carouselWide,
  carouselCategoryPortrait,
  carouselCategoryWide,
  carouselPortraitAmagi,
  carouselWideAmagi,
  carouselPortraitBell,
  carouselWideBell,
  carouselPortraitCrave,
  carouselWideCrave,
  carouselWideAmagiLiveEvent,
  channelsRailAmagi,
  gridHorizontalPortrait,
  gridHorizontalWide,
  gridVerticalPortrait,
  gridVerticalWide,
  carouselViewAllPortrait,
  carouselCategories,
  filterSort,
  carouselJumpRecommendation,
  channelsRail,
  channelsRailBellImage
} = ACCEDO_CONTROL_CONTAINER_TEMPLATES;

const Container = ({
  children,
  displayText,
  id,
  style,
  template,
  backgroundColor,
  backgroundSecondary,
  imageTitle,
  titlePosition,
  titleColor
}) => {
  const { currentLocale = {} } = useI18n();
  const { dir } = currentLocale;
  const templateClassName = styles[toCamelCase(template)] || '';
  const { width } = getResolution();
  const { pathname } = useLocation();
  const backgroundStyle = useMemo(() => {
    if (!backgroundColor) {
      // return no styles
      return {};
    }
    if (backgroundColor && backgroundSecondary) {
      // If background and secondary are present, create a gradient
      return {
        background: `linear-gradient(0deg, ${backgroundSecondary}, ${backgroundColor} 80%) no-repeat`
      };
    }

    // Use background for solid color
    return {
      backgroundColor
    };
  }, [backgroundColor, backgroundSecondary]);

  const title = useMemo(() => {
    if (imageTitle?.fileUrl) {
      return (
        <div
          id={id}
          className={cx(styles.containerTitle, styles.imageTitle)}
          style={{ justifyContent: titlePosition }}
          dir={dir}
        >
          <img src={imageTitle.fileUrl} alt="Title" />
        </div>
      );
    }

    if (displayText) {
      const titleStyles = {
        color: titleColor,
        textAlign: titlePosition
      };

      const prefixContent = getTitlePrefix(pathname);

      return (
        <div
          id={id}
          className={styles.containerTitle}
          dir={dir}
          style={titleStyles}
        >
          {prefixContent && (
            <>
              <span className={prefixContent.class}>
                {prefixContent.prefix}
              </span>
              &nbsp;
            </>
          )}
          {displayText}
        </div>
      );
    }

    return null;
  }, [imageTitle, displayText, titleColor, titlePosition, id, dir, pathname]);

  const fontStyle = size => ({
    fontSize: `${size}px`,
    lineHeight: `${size}px`
  });

  const customStyle = {
    ...backgroundStyle,
    ...style,
    ...fontStyle(R720p >= width ? 28 : 36)
  };

  return (
    <div
      className={`${styles.outerContainer} ${templateClassName}`}
      style={customStyle}
    >
      {title}
      <div
        id={displayText ? null : id}
        className={cx(styles.innerContainer, styles[dir])}
      >
        {children}
      </div>
    </div>
  );
};

Container.propTypes = {
  children: PropTypes.node,
  displayText: PropTypes.string,
  id: PropTypes.string,
  style: PropTypes.object,
  backgroundColor: PropTypes.string,
  backgroundSecondary: PropTypes.string,
  imageTitle: PropTypes.object,
  titleColor: PropTypes.string,
  titlePosition: PropTypes.oneOf(['left', 'center', 'right']),
  template: PropTypes.oneOf([
    hero,
    imageHero,
    continueWatching,
    carouselPortrait,
    carouselWide,
    carouselCategoryPortrait,
    carouselCategoryWide,
    gridHorizontalPortrait,
    gridHorizontalWide,
    gridVerticalPortrait,
    gridVerticalWide,
    carouselPortraitAmagi,
    carouselWideAmagi,
    carouselPortraitBell,
    carouselWideBell,
    carouselPortraitCrave,
    carouselWideCrave,
    carouselWideAmagiLiveEvent,
    channelsRailAmagi,
    carouselViewAllPortrait,
    carouselCategories,
    filterSort,
    carouselJumpRecommendation,
    channelsRail,
    channelsRailBellImage
  ])
};

export default React.memo(Container, (prevProps, nextProps) =>
  isEqual(prevProps, nextProps)
);
