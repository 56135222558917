import cms from '#/providers/cms';
import { PROVIDER_TYPE } from '#/config/constants';
import InfoModelBuilder from '#/models/info/info';
import Info from '#/interfaces/Info';
import { getTranslation } from '#/services/i18n';

import fetcher from '../../helpers/fetcher';

/**
 * @module services/info
 *
 * @description
 * This service provides content for each area of Info section
 * provided by this template: About, Terms, Help and Contact areas.
 */

const getContent = (key: string) => {
  const content = (getTranslation(key, {
    _: ''
  }) as unknown) as Awaited<string | null>;
  return content;
};

/**
 * Get About content
 * @returns {Promise<Info>} Content for About
 */
const getAbout = async (): Promise<Info> => {
  const content = getContent('aboutContent');
  if (content) {
    return { content };
  }
  const rawAbout = await fetcher({
    cacheId: `${PROVIDER_TYPE.cms}-about`,
    fetchFn: () => cms.getAbout()
  });
  return new InfoModelBuilder(rawAbout).create();
};

/**
 * Get Terms content
 * @returns {Promise<Info>} Content for Terms
 */
const getTerms = async (): Promise<Info> => {
  const content = getContent('termsContent');
  if (content) {
    return { content };
  }
  const rawTerms = await fetcher({
    cacheId: `${PROVIDER_TYPE.cms}-terms`,
    fetchFn: () => cms.getTerms()
  });
  return new InfoModelBuilder(rawTerms).create();
};

/**
 * Get Help content
 * @returns {Promise<Info>} Content for Help
 */
const getHelp = async (): Promise<Info> => {
  const content = getContent('helpContent');
  if (content) {
    return { content };
  }
  const rawHelp = await fetcher({
    cacheId: `${PROVIDER_TYPE.cms}-help`,
    fetchFn: () => cms.getHelp()
  });
  return new InfoModelBuilder(rawHelp).create();
};

/**
 * Get Contact content
 * @returns {Promise<Info>} Content for Contact
 */
const getContact = async (): Promise<Info> => {
  const content = getContent('contactContent');
  if (content) {
    return { content };
  }
  const rawContact = await fetcher({
    cacheId: `${PROVIDER_TYPE.cms}-contact`,
    fetchFn: () => cms.getContact()
  });
  return new InfoModelBuilder(rawContact).create();
};

export { getAbout, getTerms, getHelp, getContact };
