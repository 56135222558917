import ovp from '#/providers/ovp';
import Show from '#/models/show/show';
import { PROVIDER_TYPE } from '#/config/constants';

import fetcher from '../../helpers/fetcher';

const getShow = async id => {
  const rawShow = await fetcher({
    cacheId: `${PROVIDER_TYPE.ovp}-show-${id}`,
    fetchFn: () => ovp.getTvShowById(id)
  });
  return Show(rawShow);
};

export { getShow };
