import React from 'react';
import PropTypes from 'prop-types';
import { FocusDiv } from '@accedo/vdkweb-tv-ui';
import { withFocus } from '@accedo/vdkweb-navigation';
import { get as _get } from 'lodash';

import useI18n from '#/hooks/useI18n';

import { addNavToItems, Direction } from './addNavToItems';

const { Horizontal, Vertical } = Direction;

const Div = () => <div />;
// eslint-disable-next-line react/jsx-no-useless-fragment
const FragmentWithoutProps = ({ children }) => <>{children}</>;

FragmentWithoutProps.propTypes = {
  children: PropTypes.node
};

const FocusFragment = withFocus(FragmentWithoutProps);

const Layout = ({
  className,
  components,
  defaultFocusIndex,
  direction,
  getComponents,
  loop,
  nav: wrapperNav,
  style,
  skipForwardFocus
}) => {
  const { currentLocale = {} } = useI18n();
  const { dir } = currentLocale;
  const parentId = wrapperNav.id;
  const usedComponents = components || getComponents();
  const childIdPrefix = `${parentId}-CHILD-`;
  const focusIndex =
    defaultFocusIndex ||
    usedComponents.findIndex(component => !component.skipNavigation);
  const defaultForwardFocus = usedComponents.length
    ? _get(
        usedComponents,
        `${focusIndex}.props.nav.id`,
        `${childIdPrefix}${focusIndex}`
      )
    : '';

  const parentNav = {
    useLastFocus: true,
    ...wrapperNav,
    forwardFocus: skipForwardFocus ? '' : defaultForwardFocus
  };
  const Wrapper = className || style ? FocusDiv : FocusFragment;

  return (
    <Wrapper className={className} nav={parentNav} style={style}>
      {addNavToItems({
        components: usedComponents,
        direction,
        loop,
        parentNav,
        dir
      }).map(({ Component = Div, nav, props = {} }) => {
        return <Component key={nav.id} {...props} nav={nav} />;
      })}
    </Wrapper>
  );
};

Layout.propTypes = {
  className: PropTypes.string,
  components: PropTypes.arrayOf(
    PropTypes.shape({
      Component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
      props: PropTypes.object,
      skipNavigation: PropTypes.bool
    })
  ),
  getComponents: PropTypes.func,
  defaultFocusIndex: PropTypes.number,
  direction: PropTypes.oneOf([Horizontal, Vertical]).isRequired,
  loop: PropTypes.bool,
  style: PropTypes.object,
  nav: PropTypes.object,
  skipForwardFocus: PropTypes.bool
};

Layout.defaultProps = {
  className: '',
  getComponents: () => [],
  nav: {}
};

export default Layout;
