/**
 * Check to see if the input dir parameter is RTL.
 *
 * @memberof module:base/util
 * @param {String} dir - String to be checked.
 * @return {Boolean} True if the string is rtl, false otherwise.
 */
const isRTL = dir => dir?.toLowerCase() === 'rtl';

export default isRTL;
