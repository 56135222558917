/**
 * @module services/episode
 * @description
 * Service to handle the fetch of episodes
 */
import { PROVIDER_TYPE } from '#/config/constants';
import Episode from '#/models/episode/episode';
import ovp from '#/providers/ovp';
// eslint-disable-next-line jest/no-mocks-import
import fallbackEpisode from '#/__mocks__/fallbackEpisode';

import fetcher from '../../helpers/fetcher';

/**
 * Cache TV Shows
 * @type {Object}
 */
const tvShows = {};

/**
 * Get the TV Show from a ID
 * @param {Object} rawEpisode Raw Episode
 * @returns {Promise<Object>} TV Show
 */
const getTvShow = async rawEpisode => {
  const { metadata } = rawEpisode;
  const showId = metadata.find(item => item.name === 'VOD$tvShowId');
  if (tvShows[showId.value]) {
    return tvShows[showId.value];
  }

  const tvShowPayload = await fetcher({
    cacheId: `${PROVIDER_TYPE.ovp}-show-${showId.value}`,
    fetchFn: () => ovp.getTvShowById(showId.value)
  });

  const {
    credits,
    categories,
    images,
    tvSeasonCount,
    description
  } = tvShowPayload;

  tvShows[showId.value] = {
    credits,
    categories,
    images,
    tvSeasonCount,
    tvShowDescription: description
  };

  return tvShows[showId.value];
};

/**
 * Get episodes from a season
 * @param {String|number} id Season Id
 * @returns {Promise<Array<Episode>>} Episodes
 */
const getEpisodes = async id => {
  const rawEpisodes = await fetcher({
    cacheId: `${PROVIDER_TYPE.ovp}-season-episodes-${id} `,
    fetchFn: () => ovp.getTvSeasonEpisodesById(id)
  });

  const episodes = await Promise.all(
    rawEpisodes.entries.map(async rawEpisode => {
      const rawTvShow = await getTvShow(rawEpisode);

      const fullRawEpisode = {
        ...rawTvShow,
        ...rawEpisode
      };

      return Episode(fullRawEpisode);
    })
  );
  episodes.sort(
    (episodeA, episodeB) => episodeA.episodeNumber - episodeB.episodeNumber
  );
  return episodes;
};

const getFallbackEpisodes = numberOfEpisodes => {
  const fallbackEpisodes = [];
  for (let i = 0; i < numberOfEpisodes; i += 1) {
    fallbackEpisode.id = `${Math.random()}-${fallbackEpisode.id}`;
    fallbackEpisodes.push(Episode(fallbackEpisode));
  }
  return fallbackEpisodes;
};

/**
 * Get an episode
 * @param {String|number} id Episode Id
 * @returns {Promise<Episode>} Episode
 */
const getEpisode = async id => {
  const rawEpisode = await fetcher({
    cacheId: `${PROVIDER_TYPE.ovp}-episode-${id}`,
    fetchFn: () => ovp.getEpisodeById(id)
  });
  if (!rawEpisode) {
    return null;
  }
  const rawTvShow = await getTvShow(rawEpisode);
  const fullRawEpisode = {
    ...rawEpisode,
    ...rawTvShow
  };
  return Episode(fullRawEpisode);
};

const getCurrentSeasonIndex = (episode, seasons) => {
  const currentEpisodeSeasonId = episode.seasonId;
  const seasonIds = seasons.map(seasonItem => seasonItem.id);
  return seasonIds.indexOf(currentEpisodeSeasonId);
};

const getCurrentEpisodeIndex = (episode, episodes) => {
  const currentEpisodeNumber = episode.episodeNumber;
  const episodeNumbers = episodes.map(episodeItem => episodeItem.episodeNumber);
  return episodeNumbers.indexOf(currentEpisodeNumber);
};

const getPrevEpisodeFromPrevSeason = async (episode, seasons) => {
  const currentSeasonIndex = getCurrentSeasonIndex(episode, seasons);
  if (currentSeasonIndex > 0) {
    const previousSeason = seasons[currentSeasonIndex - 1];
    const previousSeasonEpisodes = await getEpisodes(previousSeason.id);
    previousSeasonEpisodes.sort(
      (episodeA, episodeB) => episodeA.episodeNumber - episodeB.episodeNumber
    );
    return previousSeasonEpisodes[previousSeasonEpisodes.length - 1];
  }
  return null;
};

const getPrevEpisode = async (episode, episodes, seasons) => {
  const currentEpisodeIndex = getCurrentEpisodeIndex(episode, episodes);
  if (currentEpisodeIndex > 0) {
    return episodes[currentEpisodeIndex - 1];
  }
  return getPrevEpisodeFromPrevSeason(episode, seasons);
};

const getNextEpisodeFromNextSeason = async (episode, seasons) => {
  const currentSeasonIndex = getCurrentSeasonIndex(episode, seasons);
  if (currentSeasonIndex < seasons.length - 1) {
    const nextSeason = seasons[currentSeasonIndex + 1];
    const nextSeasonEpisodes = await getEpisodes(nextSeason.id);
    nextSeasonEpisodes.sort(
      (episodeA, episodeB) => episodeA.episodeNumber - episodeB.episodeNumber
    );
    return nextSeasonEpisodes[0];
  }
  return null;
};

const getNextEpisode = async (episode, episodes, seasons) => {
  const currentEpisodeIndex = getCurrentEpisodeIndex(episode, episodes);
  if (currentEpisodeIndex < episodes.length - 1) {
    return episodes[currentEpisodeIndex + 1];
  }
  return getNextEpisodeFromNextSeason(episode, seasons);
};

export {
  getEpisode,
  getEpisodes,
  getNextEpisode,
  getPrevEpisode,
  getFallbackEpisodes
};
