// eslint-disable-next-line max-classes-per-file
import LiveProgram from '#/interfaces/live/LiveProgram';
import { parseXMLTVDateToDate } from '#/utils/xmltv';
import { ContentType } from '#/interfaces/ContentType';
import { getCloudinaryImage } from '../utils';

class LiveModelBuilder {
  liveProgram: LiveProgram;

  rawLiveProgram: any;

  timeDiffMS: number;

  constructor(rawLiveProgram: any) {
    this.rawLiveProgram = rawLiveProgram;
    this.liveProgram = {
      channelId: rawLiveProgram['@_channel'],
      cmsChannelId: '',
      id: rawLiveProgram?.title?.['#text'],
      description: rawLiveProgram?.desc?.['#text'],
      endTime: 0,
      startTime: 0,
      title: rawLiveProgram.title?.['#text'],
      videoUrl: '',
      railPoster: rawLiveProgram?.icon?.['@_src'],
      type: ContentType.LIVE,
      images: []
    };
    this.timeDiffMS = 0;
  }

  buildDates(firstProgramStart: number) {
    const startCurrentDay = new Date().setHours(0, 0, 0, 0);
    const originalStartDate = (parseXMLTVDateToDate(
      this.rawLiveProgram['@_start']
    ) as Date).getTime();
    this.timeDiffMS = startCurrentDay - firstProgramStart;
    const startDate = originalStartDate + this.timeDiffMS;
    const endDate =
      (parseXMLTVDateToDate(this.rawLiveProgram['@_stop']) as Date).getTime() +
      this.timeDiffMS;
    this.liveProgram.startTime = startDate;
    this.liveProgram.endTime = endDate;
    return this;
  }

  buildTitle() {
    const subtitleString = this.rawLiveProgram?.['sub-title']
      ? ` -  ${this.rawLiveProgram?.['sub-title']?.['#text']}`
      : '';
    this.liveProgram.title = `${this.rawLiveProgram.title['#text']}${subtitleString}`;
    return this;
  }

  buildVideoUrl(channel: any) {
    this.liveProgram.videoUrl = channel.streamUrl;
    return this;
  }

  buildCMSChannelId(channel: any) {
    this.liveProgram.cmsChannelId = `${channel._meta.id}`;
    return this;
  }

  buildId(channel: any) {
    this.liveProgram.id = `${channel._meta.id}-${this.rawLiveProgram.title['#text']}-${this.liveProgram.startTime}`;
    return this;
  }

  private _extractXmlImg = (programIcon: any): string | false => {
    let icon;

    if (Array.isArray(programIcon)) {
      // eslint-disable-next-line prefer-destructuring
      icon = programIcon[0];
    } else {
      icon = programIcon;
    }

    return icon?.['@_src'] ? icon['@_src'] : false;
  };

  buildImages() {
    const icon = this._extractXmlImg(this.rawLiveProgram?.icon);
    if (!icon) {
      return this;
    }
    this.liveProgram.railPoster = getCloudinaryImage({
      imgUrl: `https://res.cloudinary.com/accedotv/image/fetch/{transforms}/${icon}`,
      width: 1120,
      height: 364,
      crop: true
    });
    this.liveProgram.poster = getCloudinaryImage({
      imgUrl: `https://res.cloudinary.com/accedotv/image/fetch/{transforms}/${icon}`,
      width: 700
    });
    return this;
  }

  /**
   * Return/create the final LiveProgram object.
   * @returns {LiveProgram} "this" - Current LiveModelBuilder instance.
   */
  create(): LiveProgram {
    return this.liveProgram;
  }
}

const LiveModel = (rawLiveProgram: any): LiveProgram => {
  const {
    id,
    images,
    type,
    channelId,
    endTime,
    mediaId,
    startTime,
    title,
    videoUrl
  } = rawLiveProgram;

  const getId = (): string => {
    return id;
  };
  const getImages = (): [] => {
    return images;
  };
  const getType = (): string => {
    return type;
  };
  const getChannelId = (): string => {
    return channelId;
  };
  const getEndTime = (): number => {
    return endTime;
  };
  const getMediaId = (): string => {
    return mediaId;
  };
  const getStartTime = (): number => {
    return startTime;
  };
  const getTitle = (): string => {
    return title;
  };
  const getVideoUrl = (): string => {
    return videoUrl;
  };

  return {
    id: getId(),
    images: getImages(),
    type: getType(),
    channelId: getChannelId(),
    endTime: getEndTime(),
    mediaId: getMediaId(),
    startTime: getStartTime(),
    title: getTitle(),
    videoUrl: getVideoUrl()
  };
};

export class SimpleLiveModelBuilder<T extends LiveProgram> {
  liveProgram: T;

  constructor(liveProgram: T) {
    this.liveProgram = liveProgram;
  }

  /**
   * Return/create the final LiveProgram object.
   * @returns {LiveProgram} "this" - Current LiveModelBuilder instance.
   */
  create(): LiveProgram {
    const newLiveProgram: LiveProgram = LiveModel(this.liveProgram);
    return newLiveProgram;
  }
}

export default LiveModelBuilder;
