import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import ContentGrid from '#/components/ContentGrid/ContentGrid';
import BannerItem from '#/components/Item/BannerItem/BannerItem';

import useI18n from '#/hooks/useI18n';
// import { getChannelData } from '#/services/ovp';
// import { AuthContext } from '#/context/AuthContext';
// import { getProgramDataAmagi } from '#/services/ovp/live';

// import amagiOvpV2 from '#/providers/ovp/amagi/amagi_live_v2';
import { AuthContext } from '#/context/AuthContext';
import { CONTAINER_ITEM_TYPES, ROUTES } from '#/config/constants';
import bannerShelfTheme from './bannerShelf.scss';
import { getBannerItem } from './getBannerItem';

const itemWidth = 1920;
const itemHeight = 430;

const BannerShelf = ({ onClick, items, config = {}, nav = {}, ds }) => {
  const { currentLocale = {} } = useI18n();
  const { pathname } = useLocation();
  const { dir } = currentLocale;
  const [data, setData] = useState([]);
  const authContext = useContext(AuthContext);

  useEffect(() => {
    // if (ds) {
    //   // Don't fetch from API if data source is provided; fetch live channels data instead
    //   return;
    // }

    setData([
      pathname.includes('crave') ? getBannerItem('crave') : getBannerItem('tsn')
    ]);
  }, []);

  return (
    <ContentGrid
      data={data}
      // ds={ds}
      dir={dir}
      keyProperty="id"
      itemWidth={itemWidth}
      itemHeight={itemHeight}
      horizontal
      headPadding={0}
      tailPadding={0}
      spacing={0}
      width={1920}
      loop
      theme={bannerShelfTheme}
      onClick={() => {
        onClick({ type: CONTAINER_ITEM_TYPES.Other });
        // if (authContext.isAuthenticated) {
        //   onClick({ type: CONTAINER_ITEM_TYPES.Other });
        //   return;
        // }
        // onClick({ link: ROUTES.login });
      }}
      DisplayComponent={BannerItem}
      displayComponentProps={{ height: itemHeight }}
      nav={{
        ...nav,
        id: config.id || nav.id
      }}
      initialState={{
        id: items?.[0]?.id,
        position: 0
      }}
    />
  );
};

BannerShelf.propTypes = {
  onClick: PropTypes.func,
  ds: PropTypes.object.isRequired,
  items: PropTypes.array,
  config: PropTypes.object,
  nav: PropTypes.object
};

export default BannerShelf;
