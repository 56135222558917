import React, { useEffect, useState, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

import ContentGrid from '#/components/ContentGrid/ContentGrid';
import ChannelBannerImageItem from '#/components/Item/ChannelBannerlImageItem/ChannelBannerImageItem';

import { AuthContext } from '#/context/AuthContext';

// import { getChannelData } from '#/services/ovp/live';

// import { actionClickItem } from '#/services/analytics';
import useI18n from '#/hooks/useI18n';

import { ROUTES } from '#/config/constants';
import useHistoryPush from '#/hooks/history/useHistoryPush';
import channelBannerTheme from './channelBannerImage.scss';
import { getBannerImages } from './getBannerImages';

const itemWidth = 1120;
const itemHeight = 364;
const dotsSpacing = 60;

const ChannelBannerImageShelf = ({
  onClick,
  items,
  config = {},
  nav = {},
  ds
}) => {
  const [data, setData] = useState([]);
  const { currentLocale = {} } = useI18n();
  const { dir } = currentLocale;
  const authContext = useContext(AuthContext);
  const { userId } = authContext?.getUser?.() || {};
  const historyPush = useHistoryPush();

  useEffect(() => {
    const channels = getBannerImages('tsn');
    setData(channels);
  }, []);

  // useEffect(() => {
  //   if (ds) {
  //     // Don't fetch from API if data source is provided
  //     return;
  //   }

  //   const setChannelData = async () => {
  //     const channelsData = await getChannelData(userId, true);
  //     setData(channelsData?.entries || []);
  //   };
  //   setChannelData();
  // }, []);

  const ChannelItem = useMemo(() => {
    // if (ds) {
    //   // eslint-disable-next-line react/function-component-definition
    //   return props => <ChannelBannerItem {...props} />;
    // }

    // eslint-disable-next-line react/function-component-definition
    return props => <ChannelBannerImageItem {...props} useAmagiOvp />;
  }, []);

  if (!data.length && !ds) {
    return null; // TODO: add loader?
  }

  return (
    <ContentGrid
      data={data}
      // ds={ds || null}
      dir={dir}
      keyProperty={ds ? 'id' : 'channelId'}
      itemWidth={itemWidth}
      itemHeight={itemHeight}
      horizontal
      headPadding={0}
      tailPadding={0}
      spacing={0}
      height={itemHeight + dotsSpacing}
      width={1920}
      loop
      theme={channelBannerTheme}
      onClick={source => {
        if (authContext.isAuthenticated) {
          historyPush({
            path: ROUTES.player,
            state: {
              asset: source.data,
              isLive: true,
              videoUrl: source.data?.videoUrl,
              miniguide: false,
              overlay: true
            }
          });
          return;
        }
        onClick({ link: ROUTES.login });
      }}
      DisplayComponent={ChannelItem}
      displayComponentProps={{ height: itemHeight }}
      nav={{
        ...nav,
        id: config.id || nav.id
      }}
      initialState={{
        id: items?.[0]?.id,
        position: 0
      }}
      showDots
      dotsClassName="channelRailDots"
    />
  );
};

ChannelBannerImageShelf.propTypes = {
  onClick: PropTypes.func,
  items: PropTypes.array,
  ds: PropTypes.object,
  config: PropTypes.object,
  nav: PropTypes.object
};

export default ChannelBannerImageShelf;
