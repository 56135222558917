import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withFocus } from '@accedo/vdkweb-navigation';

import getResolution from '#/utils/getResolution';
import { R720p } from '#/theme/variables/breakpoints';
import ImageWithPreload from '#/components/ImageWithPreload/ImageWithPreload';
import useItemStyle from '#/hooks/useItemStyle';
import { getTranslation } from '#/services/i18n';
import {
  CONTAINER_ITEM_TYPES,
  ITEM_TYPES,
  IMAGE_TYPE
} from '#/config/constants';
import useI18n from '#/hooks/useI18n';
import { getTextTranslation } from '#/services/i18n/translate';
import assetItemTheme from './assetItem.scss';

/** Variable required to obtain as a result a dimension in vw */
const TITLE_CONTAINER_HEIGHT = 5.2;

const OrigAssetItem = ({
  containerStyle,
  data,
  displayType,
  height,
  label,
  nav,
  onClick,
  style,
  isHome,
  theme,
  type,
  width
}) => {
  const { width: screenWidth } = getResolution();
  const customStyle = {
    ...(R720p >= screenWidth && { fontSize: '20px', padding: '5px 5px' })
  };

  let typeItem;
  switch (type) {
    case ITEM_TYPES.Wide:
    case ITEM_TYPES.GridLandscape:
      typeItem = type;
      break;

    case ITEM_TYPES.SearchEpisode:
      typeItem = IMAGE_TYPE.BACKDROP;
      break;

    case ITEM_TYPES.SearchMovie:
    default:
      typeItem = IMAGE_TYPE.POSTER;
      break;
  }

  const { itemStyle } = useItemStyle({
    data,
    width,
    height,
    type: `${typeItem}`
  });

  const { viewAll, displayText } = data;
  const typeClassName = theme[type] || '';
  const showTitle = !viewAll && data.type !== 'live';
  const [translatedTitle, setTrasnlatedTitle] = useState(data.title);
  const { currentLocale } = useI18n();
  useEffect(() => {
    if (currentLocale?.code?.includes('en') || !showTitle || !isHome) {
      return;
    }

    getTextTranslation(data.title).then(text => {
      setTrasnlatedTitle(text);
    });
  }, []);

  const backgroundClassName = `${theme.background} ${
    nav.isFocused ? theme.backgroundFocused : ''
  } ${viewAll ? theme.viewAllBackground : ''}`;

  const wrapperStyle = {
    ...containerStyle,
    ...(width ? { width } : {}),
    ...(height ? { height } : {}),
    ...(displayType ? { display: displayType } : { display: 'inline-block' })
  };

  const imageHeightNumber =
    containerStyle && +containerStyle.height.replace('vw', '');
  const finalImageHeight = `${imageHeightNumber - TITLE_CONTAINER_HEIGHT}vw`;

  const titleTheme =
    type === CONTAINER_ITEM_TYPES.Bookmark
      ? `${theme.title} ${theme['title-continue-watching']}`
      : `${theme.title} ${theme['title-default']}`;

  const titleContainerClassName = nav.isFocused
    ? `${titleTheme} ${theme['title-focused']}`
    : titleTheme;

  return (
    <div style={wrapperStyle}>
      <div
        className={backgroundClassName}
        style={style}
        onMouseOver={nav.onMouseOver}
        onMouseOut={nav.onMouseOut}
      >
        <ImageWithPreload
          src={itemStyle.imageUrl}
          height={finalImageHeight}
          width={containerStyle?.width}
          // Change the default placeholder
          viewAll={viewAll || data.type === 'live' || false}
        />
        {viewAll ? (
          <div className={theme.viewAll}>
            <div>{getTranslation('viewAll')}</div>
            <div>{displayText}</div>
          </div>
        ) : (
          <div className={`${theme.contentContainer} ${typeClassName}`}>
            {data.progress && (
              <div className={theme.progress}>
                <span style={{ width: `${data.progress}%` }} />
              </div>
            )}
          </div>
        )}
        <button
          className={theme.clickArea}
          aria-label={label}
          onClick={onClick}
        />
        {showTitle && (
          <div
            style={{ ...customStyle, height: `${TITLE_CONTAINER_HEIGHT}vw` }}
            className={titleContainerClassName}
          >
            {translatedTitle}
          </div>
        )}
      </div>
    </div>
  );
};

OrigAssetItem.propTypes = {
  containerStyle: PropTypes.object,
  data: PropTypes.object.isRequired,
  displayType: PropTypes.string,
  height: PropTypes.any,
  label: PropTypes.string,
  nav: PropTypes.object,
  onClick: PropTypes.func,
  style: PropTypes.any,
  theme: PropTypes.object,
  type: PropTypes.string,
  isHome: PropTypes.bool,
  width: PropTypes.any
};

OrigAssetItem.defaultProps = {
  theme: assetItemTheme,
  label: '',
  nav: {}
};

const AssetItem = withFocus(OrigAssetItem);

// istanbul ignore else
if (__TEST__) {
  AssetItem._test = {
    OrigAssetItem
  };
}

export default AssetItem;
