import { device } from '@accedo/xdk-core';

const forgotPassword = async username => username;

const getUser = async () => {
  const { storageManager } = device;
  const storage = await storageManager.getStorage('local');
  const authData = storage.get('authData');
  return (authData && JSON.parse(authData)) || null;
};

const _clearUser = async () => {
  const { storageManager } = device;
  const storage = await storageManager.getStorage('local');
  storage.set('authData', '');
};

const _saveUser = async rawUser => {
  try {
    const { storageManager } = device;
    const storage = await storageManager.getStorage('local');
    storage.set('authData', JSON.stringify(rawUser));
  } catch (e) {
    console.error(`[ERROR] Unable to store user data`);
    throw e;
  }
};

export default { forgotPassword, getUser, _clearUser, _saveUser };
