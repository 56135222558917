import { get, set } from '#/providers/settings';
import { LocaleSetting, SettingsEnum } from '#/models/setting/setting';

/**
 * Get locale user settings
 * @returns {Promise<LocaleSetting>} User locale settings
 */
const getLocale = () => {
  return get<LocaleSetting>(SettingsEnum.locale);
};

/**
 * Set/add a locale setting
 * @param {LocaleSetting} value User locale settings
 * @returns {void}
 */
const setLocale = (value: LocaleSetting) => {
  return set<LocaleSetting>(SettingsEnum.locale, value);
};

export default { getLocale, setLocale };
