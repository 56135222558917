import { useContext, useCallback } from 'react';
import { I18nContext, I18nSetContext } from '#/context/i18nContext';

import { init, setLocale, getLocales } from '#/services/i18n';

let requestPending = false;

const useI18n = () => {
  const i18nContext = useContext(I18nContext);
  const setI18Context = useContext(I18nSetContext);

  if (i18nContext === undefined) {
    throw new Error('useI18n must be used within a I18nContext');
  }

  const initFn = useCallback(async () => {
    if (requestPending) {
      return;
    }

    requestPending = true;
    const currentLocale = await init();
    const availableLocales = await getLocales();
    setI18Context({
      isInitiated: true,
      currentLocale,
      locales: availableLocales
    });
    requestPending = false;
  }, [setI18Context]);

  const setAppLocale = useCallback(
    async localeCode => {
      const currentLocale = await setLocale(localeCode);

      setI18Context({
        currentLocale
      });
    },
    [setI18Context]
  );

  const getAppCurrentLocale = useCallback(() => {
    const { isInitiated, currentLocale } = i18nContext;
    if (!isInitiated) {
      initFn();
      return {};
    }
    return currentLocale;
  }, [i18nContext, initFn]);

  const getAppLocales = useCallback(() => {
    const { isInitiated, locales } = i18nContext;
    if (!isInitiated) {
      initFn();
      return {};
    }
    return locales;
  }, [i18nContext, initFn]);

  const getDefaultLocale = useCallback(() => {
    const { defaultLocale } = i18nContext;
    return defaultLocale;
  }, [i18nContext]);

  return {
    setLocale: setAppLocale,
    currentLocale: getAppCurrentLocale(),
    locales: getAppLocales(),
    defaultLocale: getDefaultLocale()
  };
};

export default useI18n;
