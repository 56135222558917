/**
 * @module services/analytics/dummy
 * @description
 * Dummy implementatinon of analytics. Triggers logs using the logger to check that everything works as expected without rely on a external library implementation.
 */
import logger from '#/utils/logger';

/**
 * Dummy analytics providers for testing purposes
 *
 * @param {object} opts Object with all the variables that the service expects
 * @return {*} result of the logger
 */
const dummy = opts => {
  return logger.info(opts);
};

export default dummy;
