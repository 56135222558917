import accedoOVP from './accedo/accedoOvp';
import amagiOVP from './amagi/amagi';

const { getTvListings, getChannelData, getChannelTvListings } = amagiOVP;

const ovp = {
  ...accedoOVP,
  getChannelData,
  getTvListings,
  getChannelTvListings
};

export const accedoOvp = accedoOVP;
export const amagiOvp = {
  getTvListings,
  getChannelData,
  getChannelTvListings
};

export default ovp;
