/**
 * @module services/i18n/polyglot
 * @description
 * Analytics implementation based on {@link https://www.npmjs.com/package/polyglot|AirBnB Polyglot}
 */
import Polyglot from 'node-polyglot';

let polyglot = new Polyglot({
  locale: '',
  phrases: [],
  interpolation: { prefix: '{{', suffix: '}}' }
});

const check = () => {
  if (!polyglot) {
    throw new Error(
      'No polyglot instance. Please init the dictionary and set a locale!'
    );
  }
};

/**
 * Sets the dictionary to get the translations
 * @param {object} dictionary key/value object with the strings
 * @param {string} [locale] value for the locale
 * @return {boolean} true if ok
 */
const setDictionary = (dictionary, locale) => {
  polyglot = new Polyglot({
    locale: locale || '',
    phrases: dictionary,
    interpolation: { prefix: '{{', suffix: '}}' }
  });
};

/**
 * Gets the translation for a key
 * @param {string} key Label for the string
 * @param {*} opts  Object with the values for the interpolation
 * @returns {string} Translated key
 */
const get = (key, opts) => {
  check();
  return polyglot.t(key, {
    _: `[${key}]`,
    ...opts
  });
};

export default {
  get,
  setDictionary
};
