import tsnBanner from '#/static/images/banners/tsn-banner.png';
import craveBanner from '#/static/images/banners/crave-banner.png';

export const getBannerItem = logo => {
  const image = logo === 'crave' ? craveBanner : tsnBanner;
  return {
    id: '55555',
    logo: image,
    images: [
      {
        type: 'hero',
        width: 1920,
        height: 1080,
        url: image,
        templateUrl: image,
        id: ''
      }
    ],
    title: '',
    categoryId: '',
    category: '',
    coverUrl: image,
    backgroundUrl: image,
    link: '/login',
    metadata: '',
    rawMetadata: {
      year: 2015,
      duration: '',
      categories: []
    },
    description: '',
    credits: [],
    duration: '',
    type: '',
    seasonCount: 0,
    episodeCount: 0,
    template: '',
    publishedDate: 0,
    ovp: '',
    categories: ''
  };
};
